import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import qs from 'qs';
import { useLocation  } from 'react-router-dom';

import { titledHeader } from '../../utils/table';
import { FaSortUp } from 'react-icons/fa';
import { FaSortDown } from 'react-icons/fa';
import { saveToQuery } from './jobTableUtils';

const JobTableColumnHeader = ({ column_id, isSortable }) => {
  const { t } = useTranslation();
  let location = useLocation();

  const { isAscending, isDescending } = useMemo(()=>{
    const query = qs.parse(location.search.replace('?',''));
    const ordering = query?.ordering;

    if (ordering === column_id) {
      return { isAscending: true }
    }
    if (ordering === '-' + column_id) {
      return { isDescending: true }
    }
    return ({});
  }, [ location ]);

  const setOrdering = useCallback(ascending => {
    saveToQuery({
      ordering: (ascending ? '' : '-') + column_id,
      page: 1,
    })
  }, [])

  return (
    <span>
      { titledHeader(t(`jobTable.columns.${column_id}`)) }
      {isSortable &&
        <div className="d-inline-flex ml-1">
          <Button
            color="link"
            className={`p-0 ${isAscending && "text-danger"}`}
            onClick={() => { setOrdering(true) }}
          >
            <FaSortUp/>
          </Button>
          <Button
            color="link"
            className={`p-0 ${isDescending && "text-danger"}`}
            onClick={() => { setOrdering(false) }}
          >
            <FaSortDown/>
          </Button>
        </div>

      }
    </span>
  )
};

JobTableColumnHeader.propTypes = {
  column_id: PropTypes.string,
  isSortable: PropTypes.bool,
}

export default JobTableColumnHeader;
