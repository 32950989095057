/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';


export default class Area extends Component {
  render() {
    return (
      <div className="area">
        <div className="area-header font-weight-bold">
          {this.props.title}
        </div>
        <div className="area-body">
          {this.props.children}
        </div>
      </div>
    );
  }
}

Area.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
