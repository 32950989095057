/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import PropTypes from 'prop-types';

import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';
import ConfirmationModal from '../components/confirmationModal';

@ErrorBoundary((props) => {
  return `${props.t('cluster_management')}`;
})
@withTranslation()
@withSendRequest
export default class ClusterManagement extends Component {
  state = {
    clusterState: undefined,
    userMsg: "",
    adminMsg: "",
    retryPeriod: 30,

    modal: false,
    nestedModal: false,
    warningMessage: ""
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggleNested = () => {
    this.setState({
      nestedModal: !this.state.nestedModal,
    });
  };

  toggleAll = () => {
    this.setState({
      modal: !this.state.modal,
      nestedModal: !this.state.nestedModal,
    });
  };

  userMsgChange = (e) => {
    this.setState({ userMsg: e.target.value });
  };

  adminMsgChange = (e) => {
    this.setState({ adminMsg: e.target.value });
  };

  numericInputChange = (e) => {
    this.setState({ retryPeriod: e.target.value });
  };

  componentDidMount() {
    this.props.sendRequest("get", '/system/')
      .then((response) => {
        if (response.data.status === 'DOWN') {
          this.setState({
            clusterState: response.data.status,
            userMsg: response.data.user_message,
            adminMsg: response.data.admin_message,
            retryPeriod: response.data.retry_period,
          });
        }
        else
          this.setState({ clusterState: response.data.status });
      });
  }

  clusterOffModal() {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.state.modal}
        toggle={this.toggle}
        className={this.props.className}
        size="lg"
      >
        <ModalHeader toggle={this.toggle}>{t('cluster_turning_off')}</ModalHeader>
        <ModalBody>
          {t('message_for_user')}
          <Input
            data-testid='user-msg-input'
            value={this.state.userMsg}
            type="textarea"
            onChange={this.userMsgChange}
          />
          {t('message_for_admin')}
          <Input
            data-testid='admin-msg-input'
            value={this.state.adminMsg}
            type="textarea"
            onChange={this.adminMsgChange}
          />
          {t('retry_interval_input')}
          <Input
            value={this.state.retryPeriod}
            type="number"
            step="1"
            placeholder={t('number_of_seconds')}
            onChange={this.numericInputChange}
          />
          {this.clusterOffConfirmModal()}
        </ModalBody>
        <ModalFooter>
          <Button
            id="change_state"
            color="primary"
            onClick={() => {
              if (!this.state.retryPeriod)
                this.setState({ retryPeriod: 30 }, this.toggleNested());
              else
                this.toggleNested();
            }}
          >
            {t('add')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  clusterOffConfirmModal() {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.state.nestedModal}
        toggle={this.toggleNested}
        className={this.props.className}
        size="md"
      >
        <ModalHeader>{t('cluster_turning_off')}</ModalHeader>
        <ModalBody>
          <p align="center" style={{ fontsize: '12pt' }}>{t('cluster_off_confirm')}</p>
          {this.state.userMsg &&
          <div>
            <b>{t('message_for_user')}</b>
            <div>
              {this.state.userMsg}
            </div>
            <hr/>
          </div>
          }
          {this.state.adminMsg &&
          <div>
            <b>{t('message_for_admin')}</b>
            <div>
              {this.state.adminMsg}
            </div>
            <hr/>
          </div>
          }
          <b>{t('retry_interval')}</b>
          <div>
            {this.state.retryPeriod}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            id='change_state_confirm'
            className="float-left"
            color="secondary"
            onClick={() => {
              this.props.sendRequest("post", "/system/down/", {
                user_message: this.state.userMsg,
                admin_message: this.state.adminMsg,
                retry_period: this.state.retryPeriod
              })
                .then(() => {
                  this.setState({ clusterState: 'DOWN' } );
                });
              this.toggleAll();
            }}
          >
            {t('yes')}
          </Button>
          <Button color="danger" onClick={this.toggleNested}>
            {t('no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  clusterOnModal() {
    const { t } = this.props;
    return this.state.modal && (
      <ConfirmationModal
        header={t('cluster_turning_on')}
        body={t('cluster_on_confirm')}
        onClick={() => {
          this.props.sendRequest("post", "/system/up/").then(() => {
            this.setState({ clusterState: 'UP', userMsg: '', adminMsg: '', retryPeriod: 30 });
          });
        }}
        onClose={() => {this.setState({ modal: false });}}
        size='lg'
      />
    );
  }

  renderClusterOn() {
    const { t } = this.props;
    return (
      <div>
        {t('cluster_state')}
        <span style={{ color: '#4ed639' }} >
          &nbsp;{t('on')}
        </span>
        <div className="cluster-area">
          <Button
            id='cluster_off_btn'
            size="sm"
            color="danger"
            onClick={() => {
              this.toggle();
              //REFRESH STATE TO DEFAULT AFTER MODAL IS REOPENED
              this.setState({ userMsg: '', adminMsg: '', retryPeriod: 30 });
            }}
          >
            {t('turn_cluster_off')}
          </Button>
        </div>
        {this.clusterOffModal()}
      </div>
    );
  }

  renderClusterOff() {
    const { t } = this.props;
    return (
      <div>
        {t('cluster_state')}
        <span style={{ color: 'red' }} >
          &nbsp;{t('off')}
        </span>
        <div className="nested-area">
          {this.state.userMsg &&
          <div className='mb-2'>
            <span className='bold'>{t('message_for_user')}</span>
            <div>{this.state.userMsg}</div>
          </div>
          }
          {this.state.adminMsg &&
          <div className='mb-2'>
            <span className='bold'>{t('message_for_admin')}</span>
            <div>{this.state.adminMsg}</div>
          </div>
          }
          <div className='mb-2'>
            <span className='bold'>{t('retry_interval')}</span>
            &nbsp;{t('seconds', { value: this.state.retryPeriod })}
          </div>
        </div>
        <div className="cluster-area" >
          <Button size="sm" color="secondary" onClick={this.toggle} >
            {t('turn_cluster_on')}
          </Button>
          {this.clusterOnModal()}
        </div>
      </div>
    );
  }

  render() {
    if (this.state.clusterState === 'UP') {
      return this.renderClusterOn();
    }
    else if (this.state.clusterState === 'DOWN'){
      return this.renderClusterOff();
    }
    else return (<div>Loading...</div>);
  }
}

ClusterManagement.propTypes = {
  className: PropTypes.string,
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
};
