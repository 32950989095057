import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Login from '../../main/containers/login';
import { __env } from '../../envloader';
import backendTranslation from '../utils/backendTranslation';

import QcgImg from '../../assets/img/landing/qcg.png';
import { ReactComponent as PCSSImg } from '../../assets/img/landing/pcss.svg';
import { ReactComponent as EUFundsImg } from '../../assets/img/landing/EU_funds.svg';
import { ReactComponent as RPLogoImg } from '../../assets/img/landing/Logo_RP.svg';
import { ReactComponent as UEImg } from '../../assets/img/landing/UE-EFRR.svg';

const LandingPage = () => {

  const landing = __env.THEME?.landing;
  const location = useLocation();
  const { t } = useTranslation(); 
  
  return (
    <div className="landingPage">
      <div className="topbar">
        <div className="logos">
          {
            landing?.top_icon && (
              <>
                <img src={landing?.top_icon} alt="top icon" />
                <div className="line" />
              </>
            )
          }
          <img src={QcgImg} alt="QCG"/>
        </div>
        <div className="loginButton">
          <Login location={location}/>
        </div>
      </div>
      <div className="main center">
        {
          landing?.main_icon && <img src={landing?.main_icon} alt="project logo" />
        }
        <h1 className={!landing?.main_icon ? "without-margin" : ""}>{
          landing?.main_title ?
            backendTranslation(landing?.main_title)
            :
            t('landing.main_title')
        }</h1>
        <h3>{
          landing?.subtitle ?
            backendTranslation(landing?.subtitle)
            :
            t('landing.subtitle')
        }</h3>
        <div className="logo">
          {
            landing?.institution_icon ?
              <img src={landing?.institution_icon} alt="institution icon" />
              :
              <PCSSImg />
          }
        </div>
      </div>
      {
        landing?.eu_footer &&
          <div className="footer">
            <EUFundsImg />
            <RPLogoImg />
            <UEImg />
          </div>
      }
    </div>
  );
};

export default LandingPage;
