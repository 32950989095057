import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

import { 
  Breadcrumbs, 
  Container, 
  CountOfTemplates,
  CustomDropdown,
  CustomDropdownItem,
  CustomDropdownMenu,
  CustomDropdownToggle,
  DropdownText,
  FilterButton,  
  Header, 
  HeaderTitle, 
  Icon,
  Link,
  MainContent,
  SearchContainer, 
  SearchInput
} from '../../styles/styled-components/newTemplates';
import { NormalColumn, NormalRow } from '../../styles/styled-components/GlobalStyles';
import { Angel, FilterIcon, SearchIcon } from '../../../assets/img/templates/icons.js';
import { loadTemplates } from '../../actions/templates';
import axiosInstance from '../../../main/utils/axios/axiosInstance';
import AllTemplates from './allTemplates';
import Loading from '../../../main/components/loading';
import RecentlyTemplates from './recentlyTemplates';
import TemplateDetails from './templateDetails';
import TemplateFilter from './templateFilter';

const NewTemplates = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const selectDates = state => ({
    templates: state.templates.get('data'),
    isLoading: state.templates.get('isLoading')
  });

  const selectAccounts = state => ({
    inactiveAccounts: state.accounts.inactiveAccounts,
    isLoadingAccounts: state.accounts.isLoading
  });

  const { inactiveAccounts, isLoadingAccounts } = useSelector(selectAccounts);
  const { templates, isLoading } = useSelector(selectDates);
  const [ activeUrl, setActiveUrl ] = useState(null);
  const [ search, setSearch ] = useState("");
  const [ isFilter, setIsFilter ] = useState(false);
  const [ recetlyTemplates, setRecentlyTemplates ] = useState(null);
  const [ isOpenDropdown, setIsOpenDropdown ] = useState(false);
  const [ isLoadingAllParams, setIsLoadingAllParams ] = useState(true);
  const [ isTextOverflow, setIsTextOverflow ] = useState(false);
  const [ isChoosenTextOverflow, setIsChoosenTextOverflow ] = useState(false);
  const [ usersAccounts, setUsersAccounts ] = useState([]);
  const [ choosenAccounts, setChoosenAccounts ] = useState(null);

  const checkOverflow = (ele, choosen) => {
    choosen ? setIsChoosenTextOverflow(ele.offsetWidth < ele.scrollWidth) : setIsTextOverflow(ele.offsetWidth < ele.scrollWidth);
  };

  const getRecentlyTemplate = (useraccount) => {
    axiosInstance({
      url: '/templates/recently-used/',
      method: "get",
      params: {
        count: 4,
        userAccountId: useraccount
      },
      __silentFor: [ { status: 404 } ] 
    }).then(res => setRecentlyTemplates(res.data?.templates));
  };

  const filterUserAccounts = useCallback((accounts) => {
    return accounts?.filter(account => !inactiveAccounts?.includes(account?.id));
  }, [ inactiveAccounts ]);

  const getUsersAccounts = useCallback(() => {
    axiosInstance.get('/user/accounts/').then(res => { 
      setUsersAccounts(filterUserAccounts(res.data)); 
      !res.data && setIsLoadingAllParams(false);
    } );
  }, [ filterUserAccounts, setUsersAccounts ]);

  useEffect(() => {
    if (!isLoadingAccounts) {
      getUsersAccounts();
    };
  }, [ isLoadingAccounts, getUsersAccounts ]);

  useEffect(() => {
    if (choosenAccounts?.id) {
      dispatch(loadTemplates(choosenAccounts?.id));
      getRecentlyTemplate(choosenAccounts?.id);
    }
  }, [ choosenAccounts, choosenAccounts?.id, dispatch, isLoadingAllParams ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (!searchParams.has('details')) {
      setActiveUrl(null);
    }
  }, [ location.search ]);

  useEffect(() => {
    setChoosenAccounts(usersAccounts?.[0]);
  }, [ usersAccounts ]); 

  if (isLoading && isLoadingAllParams){
    return <Loading />;
  }

  if (activeUrl) {
    return <TemplateDetails templateUrl={activeUrl} />;
  }

  return (
    <Container>
      <NormalRow fullHeight style={{ alignItems: "flex-start" }}>
        <TemplateFilter isOpen={isFilter}/>
        <NormalColumn>
          <MainContent>
            <Breadcrumbs style={{ margin: "12px 0" }}>
              <Link to={'/ui/tasks/'}>{t('new_templates.main_page')}</Link>
              <img src={Angel} alt="Angel-right"/>
              <div>{t('templates')}</div>
            </Breadcrumbs>
            <Header>
              <HeaderTitle>{t('new_templates.all_templates')}</HeaderTitle>
              <NormalRow style={{ width: "auto", height: "32px" }}>
                <SearchContainer>
                  <SearchInput
                    onChange={(e) => setSearch((e.target.value.toLowerCase()))}
                    type="text"
                    placeholder={t('new_templates.search_for_template')}
                  />
                  <Icon src={SearchIcon} alt="search" />
                </SearchContainer>
                <CustomDropdown isOpen={isOpenDropdown} toggle={() => setIsOpenDropdown(!isOpenDropdown)}>
                  <CustomDropdownToggle caret disabled={!choosenAccounts}>
                    <DropdownText id={`ChoosenItem`} onMouseEnter={(event) => checkOverflow(event.target, true)}>
                      {choosenAccounts ? `${choosenAccounts?.local_user}@${choosenAccounts?.site}` : t('new_templates.no_accounts')}
                    </DropdownText>
                    {isChoosenTextOverflow && (
                      <UncontrolledTooltip placement="bottom" target={`ChoosenItem`}>
                        {`${choosenAccounts?.local_user}@${choosenAccounts?.site}`}
                      </UncontrolledTooltip>
                    )}
                  </CustomDropdownToggle>
                  <CustomDropdownMenu>
                    {
                      usersAccounts?.map(( el, index ) => (
                        <CustomDropdownItem onClick={() => setChoosenAccounts(el)} isChoosen={choosenAccounts?.id === el?.id}>
                          <DropdownText id={`DropdownItem-${index}`} onMouseEnter={(event) => checkOverflow(event.target)}>
                            {`${el?.local_user}@${el?.site}`}
                          </DropdownText>
                          {isTextOverflow && 
                          <UncontrolledTooltip placement="bottom" target={`DropdownItem-${index}`}>
                            {`${el?.local_user}@${el?.site}`}
                          </UncontrolledTooltip>}
                        </CustomDropdownItem>              
                      ))
                    }
                  </CustomDropdownMenu>
                </CustomDropdown>
                <FilterButton disabled onClick={() => setIsFilter(!isFilter)}>
                  <img src={FilterIcon} alt="filterIcon"/>
                </FilterButton>
              </NormalRow>
            </Header>
            <CountOfTemplates>{t('new_templates.total_templates')}<span>{templates?.size || 0}</span></CountOfTemplates>
            <RecentlyTemplates userAccountId={choosenAccounts?.id} setActiveUrl={setActiveUrl} recetlyTemplates={recetlyTemplates}/>
            <AllTemplates userAccountId={choosenAccounts?.id} search={search} templates={templates} setActiveUrl={setActiveUrl} isFilter={isFilter}/>
          </MainContent>
        </NormalColumn>
      </NormalRow>
    </Container>
  );
};

export default NewTemplates;