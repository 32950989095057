/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Form from '@rjsf/core';
import { withTranslation, Trans } from 'react-i18next';
import { Card, ModalHeader, ModalFooter } from 'reactstrap';
import { withRouter } from 'react-router-dom';

import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import { processForm, validateForm } from './formUtils';
import { CustomNavs, uiConditional } from '../../../main/components/forms/customNavs';
import CustomDescriptionField from '../fields/customDescriptionField';
import CustomFileWidget from '../widgets/customFileWidget';
import { customArrayFieldTemplate, customFieldTemplate } from './customFields';
import noTemplateIcon from '../../../assets/img/templates/no-template-icon.svg';
import { CustomButton, CustomModal } from '../../styles/styled-components/submitForm';

@ErrorBoundary(() => {
  return `SubmitForm`;
})
@withTranslation()
class SubmitForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...processForm(
        this.props.templateSchema,
        this.props.templateUiSchema,
        this.props.templateSchema,
        this.props.templateUiSchema,
        {}
      ),
      modal: !!this.props.initialFormData,
      nestedTab: this.props.templateUiSchema?.navConf?.navs?.[0]?.nav
    };
  }

  validate = (formData, errors) => {
    return validateForm(this.state.uiSchema, formData, errors);
  };

  handleChange = data => {
    const newState = processForm(this.props.templateSchema, this.props.templateUiSchema, this.state.schema, this.state.uiSchema, data.formData);
    this.setState(newState);
  };

  usePreviousVaribles = () => {
    const newState = processForm(
      this.props.templateSchema,
      this.props.templateUiSchema,
      this.state.schema,
      this.state.uiSchema,
      this.props.initialFormData
    );
    this.setState(newState);
  };

  toggleModal = () => this.setState({ modal: !this.state.modal });
  setNestedTabState = (tab) => {
    const newUiConditionalSchema = uiConditional(false, false, this.props.templateUiSchema, tab);
    let processedForm = processForm(this.props.templateSchema, this.props.templateUiSchema, 
      this.state.schema, newUiConditionalSchema, this.state.formData);
    processedForm.nestedTab = tab;
    this.setState(processedForm);
  };

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const previousVariables = queryParams.has('usePreviousVariables') ? queryParams.get('usePreviousVariables') === "true" : false;
    const newUiConditionalSchema = uiConditional(false, false, this.state.uiSchema, this.state.nestedTab);
    this.setState(processForm(this.props.templateSchema, this.props.templateUiSchema, this.state.schema, newUiConditionalSchema, this.state.formData));

    if (this.props.initialFormData && previousVariables) {
      this.usePreviousVaribles();
    }
  }

  render() {
    const queryParams = new URLSearchParams(this.props.location.search);
    const showModal = !queryParams.has('usePreviousVariables') ? true : false;
   
    return (
      <Card className="mt-4 mb-5 submit-card">
        {
          <div className='icon'>
            <img src={ this.props.icon || noTemplateIcon } alt="icon" />
          </div>
        }
        
        {
          this.props.initialFormData && showModal &&
            <CustomModal isOpen={this.state.modal} toggle={this.toggleModal} centered={true}>
              <ModalHeader className="submit-form-modal-header">
                <h3>{this.props.t('submit_form.modal.header')}</h3>
                <p>
                  <Trans i18nKey="submit_form.modal.body">
                    first text <b>last</b> rest
                  </Trans>
                </p>
              </ModalHeader>
              <ModalFooter >
                <CustomButton  onClick={this.toggleModal}>{this.props.t('submit_form.modal.no')}</CustomButton>
                <CustomButton primary className="ml-3" onClick={() => {this.usePreviousVaribles(); this.toggleModal();}}>
                  {this.props.t('submit_form.modal.yes')}
                </CustomButton>
              </ModalFooter>
            </CustomModal>
        }
        <Form
          schema={this.state.schema}
          uiSchema={this.state.uiSchema}
          fields={{ DescriptionField: (props) => (
            <>
              <CustomDescriptionField {...props} infoBoxMsg={this.props.messages} />
              {props.id === "root__description" ? 
                <CustomNavs 
                  className="mb-3"
                  isNested={true}
                  uiSchema={this.state.uiSchema}
                  nestedTabState={this.state.nestedTab}
                  setNestedTabState={this.setNestedTabState}
                />
                : ""}
            </>
          ) }}
          widgets={{ ExternalFileWidget: CustomFileWidget }}
          formData={this.state.formData}
          onChange={this.handleChange}
          onSubmit={this.props.onSubmit}
          onError={param => {console.error("error", param);}}
          FieldTemplate={customFieldTemplate}
          ArrayFieldTemplate={customArrayFieldTemplate}
          validate={this.validate}
          className="submit-form"
          omitExtraData
        >
          <div className="form-buttons">
            <CustomButton href={`/ui/templates`}>{this.props.t('submit_form.cancel')}</CustomButton>
            <CustomButton type="submit" primary>{this.props.t('submit_form.submit')}</CustomButton>
          </div>
        </Form>
      </Card>
    );
  }
}

SubmitForm.propTypes = {
  templateSchema: PropTypes.object.isRequired,
  templateUiSchema: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  location: PropTypes.object,
  initialFormData: PropTypes.object,
  t: PropTypes.func,
  messages: PropTypes.objectOf(PropTypes.string),
  boolenIcon: PropTypes.bool,
  icon: PropTypes.string
};

export default withRouter(SubmitForm);