import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
// import all locales suported by ui
import 'moment/locale/pl';

import Callback from './callback';
import LogoutCallback from './logoutCallback';
import { loginCallbackPath, logoutCallbackPath } from '../utils/authUtils';
import LoginToAccess from '../components/loginToAccess';
import NotFound from '../components/notFound';
import Loading from '../components/loading';
import { __env } from '../../envloader';

import TopBar from '../../qcg-ui/containers/topbar';
import Dashboard from '../../qcg-ui/containers/dashboard';
import Submit from '../../qcg-ui/containers/submit/submit';
import TasksGrid from '../../qcg-ui/containers/tasks/tasksGrid';
import JobDetails from '../../qcg-ui/containers/jobs/jobDetails';
import BackFromProfile from '../../qcg-ui/components/backFromProfile';
import LandingPage from '../../qcg-ui/components/landingPage';
import Jobs from '../../qcg-ui/containers/jobs/jobs';
import '../../qcg-ui/styles/index.scss';
import { AppWrapper, MainContent } from '../../qcg-ui/styles/styled-components/GlobalStyles';
import GloabalSidebar from '../../qcg-ui/containers/GlobalSidebar';
import NewTemplates from '../../qcg-ui/containers/templates/newTemplates';
import axiosInstance from '../utils/axios/axiosInstance';
import { addInactiveAccounts, finishLoadingAccounts } from '../../qcg-ui/actions/userAccounts';
import { bindActionCreators } from 'redux';


@withTranslation()
@connect(
  state => ({
    loginStore: state.login.get('loginData'),
    loginFinishingInProgress: state.login.get('login_finishing_in_progress')
  }),
  dispatch => {
    return bindActionCreators({
      addInactiveAccounts: addInactiveAccounts,
      finishLoadingAccounts: finishLoadingAccounts,
    }, dispatch);
  }
)
class Root extends Component {
  state = {
    isOpen: false
  };

  getUserAccounts = () => {
    axiosInstance.get('/user/accounts/').then(res => res.data?.forEach(
      ele => axiosInstance.get(`/user/accounts/${ele?.id}`, { __silentFor: [{ status: 400 }] })
      .catch( err => {
        if (err.response && err.response.status === 400) {
          this.props.addInactiveAccounts(ele?.id)
        }})
      .finally(() => this.props.finishLoadingAccounts())
    ))
  };

  componentDidUpdate() {
    this.props.loginStore && this.getUserAccounts();
  }

  renderContent = () => {

    if (this.props.loginFinishingInProgress) {
      //login finishing is in progress
      return <Loading className="text-center mt-5" />;
    }

    if (!this.props.loginStore) {
      //User not logged in
      return (
        <Switch>
          <Route exact strict path='/' component={LandingPage} />
          { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/templates/' component={LoginToAccess}/> }
          { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/submit/' component={LoginToAccess}/> }
          <Route exact path='/ui/tasks/' component={LoginToAccess} />
          <Route exact path='/ui/jobs/' component={LoginToAccess} />
          <Route path={ loginCallbackPath } exact component={Callback} />
          <Route path={ logoutCallbackPath } exact component={LogoutCallback} />
          <Route component={NotFound} />
        </Switch>
      );
    }
    else {
      //User logged in

      return (
        <AppWrapper isOpen={this.state.isOpen}>
          <GloabalSidebar
            isOpen={this.state.isOpen} 
            setIsOpen={(isOpen) => this.setState({ isOpen })}
          />
          <MainContent>
            <Switch>
              <Route exact strict path="/">
                {__env.ENABLE_DASHBOARD ? <Dashboard /> : <Redirect to={`/ui/tasks`}/>}
              </Route>
              { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/templates/' component={NewTemplates} /> }
              { __env.ENABLE_TEMPLATES_AND_SUBMIT && <Route exact path='/ui/submit/' component={Submit}/> }
              <Route exact path='/ui/tasks/' component={TasksGrid}/>
              <Route exact path='/ui/jobs/' component={Jobs}/>
              <Route exact path='/ui/jobs/:id' component={JobDetails} />
              <Route exact path='/back_from_profile' component={BackFromProfile} />
              <Route component={NotFound} />
            </Switch>
          </MainContent>
        </AppWrapper>
      );
    }
  };

  render() {

    // Global internationalization of moment library
    moment.locale(this.props.t("language_code_for_moment"));

    return (
      this.props.location.pathname !== '/' ? (
        <div style={{ height: "100%" }}>
          <TopBar />
          <Container fluid style={{ height: "calc(100% - 56px)" }}>
            <div className="main-content">
              {this.renderContent()}
            </div>
          </Container>
        </div>
      ) : (
        <div className="overflow-hidden">
          {this.renderContent()}
        </div>
      )
    );
  }
}

Root.propTypes = {
  t: PropTypes.func, //HOC
  loginStore: PropTypes.object, //HOC
  loginFinishingInProgress: PropTypes.bool, //HOC
  location: PropTypes.object
};

export default withRouter(Root);
