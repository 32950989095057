import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Collapse, Tooltip } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { 
  ButtonsContainer, 
  CollapseText, 
  NewJob, 
  OrderAgain, 
  RecentlyTemplate, 
  TemplateContainer, 
  TemplateTitle, 
  TemplatesContainer 
} from '../../styles/styled-components/newTemplates';
import { Arrow, CustomButton } from '../../styles/styled-components/jobs/Results';
import { ClockIcon } from '../../../assets/img/templates/icons.js';

const RecentlyTemplates = ({ recetlyTemplates, setActiveUrl, userAccountId }) => {
  const { t } = useTranslation();
  const history = useHistory(); 

  const [ isActive, setIsActive ] = useState(true);
  const [ isOpenTooltip, setIsOpenTooltip ] = useState(null);

  const toggle = (index) => setIsOpenTooltip(isOpenTooltip === index ? null : index);

  const formatTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp);
    return `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`;
  };

  const showTemplateDetails = (url) => {
    setActiveUrl(url);
    history.push(`/ui/templates?details${userAccountId ? `&userAccountId=${userAccountId}` : ''}`);
  };

  if (!recetlyTemplates || recetlyTemplates?.length === 0)
    return null;

  return (
    <>
      <CustomButton onClick={() => setIsActive(!isActive)}>
        <img src={ClockIcon} alt="ClockIcon"/>
        <CollapseText>{t('new_templates.recently_used_templates')}</CollapseText>
        <Arrow active={isActive}/>
      </CustomButton>
      <Collapse isOpen={isActive}
      >
        <TemplatesContainer style={{ paddingBottom: "28px" }}>
          { recetlyTemplates.map((template, index) => 
            <TemplateContainer recently key={index} style={{ height: "120px" }}>
              <RecentlyTemplate onClick={() => showTemplateDetails(template?.source)}>
                <TemplateTitle recently>
                  {template?.name}
                </TemplateTitle>
                <img src={ClockIcon} alt="ClockIcon" id={`clockIcon${index}`}/>
                <Tooltip 
                  isOpen={isOpenTooltip === index}
                  toggle={() => toggle(index)} 
                  placement="right"
                  target={`clockIcon${index}`}
                >
                  {formatTimestamp(template?.last_submit_time)}
                </Tooltip>
              </RecentlyTemplate>
              <ButtonsContainer>
                <NewJob 
                  disabled={!userAccountId}
                  to={userAccountId && `/ui/submit?templateUrl=${template?.source}&usePreviousVariables=false&userAccountId=${userAccountId}`}            
                  style={{ position: "static" }}
                >
                  {t('new_templates.new_job')}
                </NewJob>
                <OrderAgain 
                  disabled={!userAccountId}
                  to={userAccountId && `/ui/submit?templateUrl=${template?.source}&usePreviousVariables=true&userAccountId=${userAccountId}`}
                >
                  {t('new_templates.order_again')}
                </OrderAgain>
              </ButtonsContainer>
            </TemplateContainer>
          ) 
          }
        </TemplatesContainer>
      </Collapse>
    </>
  );
};

export default RecentlyTemplates;

RecentlyTemplates.propTypes = {
  recetlyTemplates: PropTypes.array,
  setActiveUrl: PropTypes.func,
  userAccountId: PropTypes.number
};