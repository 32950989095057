import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledButton = styled.button`
  border: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 5px ${({ hasChildren }) => hasChildren && "12px"};
  width: "fit-content";
  }};

  z-index:2;

  p {
    text-align: center;
    font-weight: 500;
    padding-bottom: 1px;
  }

  background-color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return "white";
    case "destructive":
      return theme.colors.red["500"];
    case "menu":
      return 'rgba(255,255,255,0)';
    default:
      return theme.colors.primary["500"];
    }
  }};

  ${({ type }) => {
    switch (type) {
    case "secondary":
      return `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px rgba(70, 79, 96, 0.16);`;
    case "destructive":
      return `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px ${theme.colors.red["500"]};`;
    case "menu":
      return `box-shadow: 0`;
    default:
      return `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px ${theme.colors.primary["500"]};`;
    }
  }};

  circle, path {
    ${({ svgProperty }) => svgProperty ?? "stroke"}: ${({ type }) => {
  switch (type) {
  case "secondary":
    return theme.colors.grey["700"];
  case "menu":
    return theme.colors.grey["600"];
  default:
    return "white";
  }
}};
  }

  border-radius: 6px;
  color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return theme.colors.grey["700"];
    case "menu":
      return theme.colors.grey["600"];
    default:
      return "white";
    }
  }};

  :hover {
    background-color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return "white";
    case "destructive":
      return theme.colors.red["600"];

    case "menu":
      return theme.colors.primary["0"];
    default:
      return theme.colors.primary["600"];
    }

  }};
  circle, path {
    ${({ svgProperty }) => svgProperty ?? "stroke"}: ${({ type }) => {
  switch (type) {
  case "menu":
    return theme.colors.grey["800"];
  default:
    return "";
  }
}}
  }
    color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return theme.colors.grey["900"];
    case "menu":
      return theme.colors.grey["900"];
    default:
      return "white";
    }
  }};
  }

  :active {
    background-color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return "white";
    case "destructive":
      return theme.colors.red["600"];
    case "menu":
      return theme.colors.primary["50"];
    default:
      return theme.colors.primary["700"];
    }
  }};
  
  circle, path {
    ${({ svgProperty }) => svgProperty ?? "stroke"}: ${({ type }) => {
  switch (type) {
  case "menu":
    return theme.colors.primary["600"];
  default:
    return "";
  }
}}
  }

  }
  :focus {
    background-color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return "white";
    case "destructive":
      return theme.colors.red["500"];
    case "menu":
      return theme.colors.grey["50"];
    default:
      return theme.colors.primary["500"];
    }
  }};
  ${({ type }) => {
    switch (type) {
    case "menu":
      return `box-shadow: 0`;
    default:
      return `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1),
      0px 0px 0px 1px ${theme.colors.primary["500"]},
      0px 0px 0px 4px rgba(94, 90, 219, 0.4);`;
    }
  }}
  }
  :disabled {
    background-color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return theme.colors.grey["0"];
    case "destructive":
      return theme.colors.red["300"];
    case "menu":
      return 'rgba(255,255,255,0)';
    default:
      return theme.colors.primary["300"];
    }
  }};
    ${({ type }) => {
    switch (type) {
    case "secondary":
      return `box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.2);`;
    case "destructive":
      return `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px ${theme.colors.red["500"]};`;
    case "menu":
      return `box-shadow: 0`;
    default:
      return `box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1), 0px 0px 0px 1px #9E9BF5;`;
    }
  }}
    color: ${({ type }) => {
    switch (type) {
    case "secondary":
      return theme.colors.grey["400"];
    default:
      return theme.colors.primary["0"];
    }
  }};

  circle, path {
    ${({ svgProperty }) => svgProperty ?? "stroke"}: ${({ type }) => {
  switch (type) {
  case "secondary":
    return theme.colors.grey["400"];
  case "menu":
    return theme.colors.grey["600"];
  default:
    return theme.colors.primary["0"];
  }
}
}
}
`;