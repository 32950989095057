/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Form, Label, Input, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Map } from 'immutable';
import { FaCheckCircle } from 'react-icons/fa';
import { FaTimesCircle } from 'react-icons/fa';

import { loadResourcesData } from '../../actions/resources';
import { withSendRequest } from '../../../main/hoc/withSendRequest';
import TaskState from '../../components/taskState';
import * as notify from '../../utils/notify';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import backendTranslation from '../../utils/backendTranslation';

@ErrorBoundary((props) => {
  return `${props.t('moving_action_modal')}`;
}, true)
@withTranslation()
@connect(
  state => ({
    queues: state.resources.getIn([ 'queues', 'list' ], Map()),
  }),
  dispatch => {
    return bindActionCreators({
      loadResourcesData: loadResourcesData,
    }, dispatch);
  }
)
@withSendRequest
export default class MovingActionModal extends Component {
  state = {
    queue: undefined,
    tasksStates: Map(),
    tasksMoving: Map(),
  };

  componentDidMount() {
    //Load queues if needed
    if (this.props.queues.isEmpty()) {
      this.props.loadResourcesData({ sendRequest: this.props.sendRequest });
    }
    //Load tasks states
    for (let i=0; i<this.props.idsArray.length; i++) {
      const id = this.props.idsArray[i];
      this.props.sendRequest('get', '/jobs/' + id + '/')
        .then(response => {
          this.setState(prevState => ({ tasksStates: prevState.tasksStates.set(id, response.data.state) }));
        });
    }
  }

  close = () => {
    this.props.onClose();
  };

  move = () => {
    const { t } = this.props;
    for (let i=0; i<this.props.idsArray.length; i++) {
      const id = this.props.idsArray[i];
      this.setState(prevState => ({ tasksMoving: prevState.tasksMoving.set(id, 'MOVING') }));
      this.props.sendRequest('patch', '/jobs/' + id + '/', { queue: this.state.queue })
        .then(response => {
          this.setState(prevState => ({ tasksMoving: prevState.tasksMoving.set(id, 'DONE') }));
        })
        .catch(error => {
          //TODO: decide if these notifications are needed - redundand info on modal dialog as icons
          notify.error(t('job_move_error_notification_title'), error?.response ? backendTranslation(error?.response.data) : error?.message);

          this.setState(prevState => ({ tasksMoving: prevState.tasksMoving.set(id, 'FAILED') }));
        });
    }
  };

  queueChanged = (event) => {
    this.setState({ queue: event.target.value });
  };

  renderQueueOptions = () => {
    return this.props.queues.keySeq().toArray().map(queue =>
      <option key={queue} value={queue}>{queue}</option>
    );
  };

  render() {
    const { t } = this.props;
    let allPending = true;
    for (let i=0; i < this.props.idsArray.length; i++) {
      if (this.state.tasksStates.get(this.props.idsArray[i]) !== 'PENDING') {
        allPending = false;
      }
    }
    return (
      <div id="moving-action-modal">
        <Modal isOpen={true} toggle={this.close}>
          <ModalHeader toggle={this.close}>{t('batch_jobs_moving')}</ModalHeader>
          <ModalBody>
            <p>{t('move_following_jobs')}:</p>
            <ul>
              {this.props.idsArray.map(id => {
                const state = this.state.tasksStates.get(id);
                const movingStatus = this.state.tasksMoving.get(id, undefined);
                return (
                  <li key={id}>
                    {id}
                    <TaskState state={state} className="mx-1"/>
                    {movingStatus === 'MOVING' && <Spinner color="secondary" size="sm" className="ml-1" />}
                    {movingStatus === 'DONE' && <span data-testid='moving-done' className="ml-1 text-secondary done-icon"><FaCheckCircle/></span>}
                    {movingStatus === 'FAILED' && <span data-testid='moving-failed' className="ml-1 text-primary failed-icon"><FaTimesCircle/></span>}
                  </li>
                );
              })}
            </ul>
            <Form className="batch-queue-mover" inline>
              <Label>{t('to_queue')}</Label>
              <Input
                data-testid='select-queue'
                defaultValue={"undefined"}
                type="select"
                name="select"
                id="queueSelect"
                bsSize="sm"
                className="mx-1"
                onChange={this.queueChanged}
                disabled={!allPending}
              >
                <option hidden disabled value={"undefined"}>{t('select')}</option>
                {this.renderQueueOptions()}
              </Input>
              <Button id="move-tasks" size="sm" onClick={this.move} disabled={!allPending || !this.state.queue}>{t('move')}</Button>
            </Form>
            {!allPending && <p className="text-danger"><small>{t('all_jobs_must_be_PENDING')}</small></p>}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

MovingActionModal.propTypes = {
  idsArray: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
  queues: PropTypes.object, //HOC
  loadResourcesData: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
  t: PropTypes.func, //HOC
};
