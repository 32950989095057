import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Navbar } from 'reactstrap';

import { theme } from '../theme';

export const StyledNavbar = styled(Navbar)`
  background: ${theme.colors.primary['700']};
`;

export const CustomDropdown = styled(Dropdown)`
  height: 100%;  
  width: 60px;
  padding-bottom: 2px;
  &:hover {
    background-color: ${theme.colors.primary[800]};
  };
`;

export const CustomDropdownToggle = styled(DropdownToggle)`
  height: 100%; 
  width: 100%;
  border-radius: 0; 
  background-color: transparent;
  box-shadow: none !important;
  border: none !important;
  &:hover, :active, :focus {
    background-color: ${theme.colors.primary[800]} !important;
  };
`;

export const CustomDropdownItem = styled(DropdownItem)`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 18px;
  
  color: ${theme.colors.grey['700']};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.medium};
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
  position: absolute !important;
  left: 50% !important;
  top: 100% !important;
  transform: translateX(-50%) !important;
`;