import styled, { css } from 'styled-components';

import { NormalColumn } from './GlobalStyles';
import { theme } from './../theme';

export const StyledIcon = styled.svg`
  width: 40px;
  height: 40px;
  flex-shrink: 0;
`;

const hoverStyles = `
  &:hover {
    font-weight: ${theme.fontWeight.semiBold};
    color: ${theme.colors.grey[800]};
    text-decoration: none;

    ${StyledIcon} {
      border-radius: 6px;
      background-color: ${theme.colors.primary[50]};

      path {
        stroke: ${theme.colors.primary[500]};
      }
    }
  }
`;

const activeStyles = css`
  font-weight: ${theme.fontWeight.semiBold};
  color: ${theme.colors.grey[800]};

  &:hover {
    color: ${theme.colors.grey[800]};
    text-decoration: none;
  }

  &:active {
    border: none;
  }

  ${StyledIcon} {
    border-radius: 6px;
    background-color: ${theme.colors.primary[500]};

    path {
      stroke: ${theme.colors.white};
    }
  }
`;

export const FixedSidebar = styled.aside`
  position: sticky;
  top: 56px;
  z-index: 100;
  height: calc(100vh - 56px);
  overflow: hidden;
  transition: width 0.3s ease-in-out;
  width: ${({ isOpen }) => isOpen ? '186px !important' : '72px !important'};
  padding: 0 10px;
  background: rgba(247, 249, 252, 0.80);
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  color: ${theme.colors.grey[700]};

  @media (max-width: 800px) {
    position: fixed;
    background-color: ${theme.colors.grey[100]};
    width: ${({ isOpen }) => isOpen ? '72px !important' : '186px !important'};
  }


  @media (min-width: 801px) {
    width: 186px;
  }
`;

export const Menu = styled(NormalColumn)`
  gap: 24px;
  padding-left: 3px;
`;

export const MenuEl = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: ${({ isOpen }) => isOpen ? '7px' : '25px'};
  transition: gap 0.3s ease-in-out;
  color: ${theme.colors.grey[800]};
  
  ${({ disabled, isActive }) => 
    disabled 
      ? `
          background-color: #f3f3f3;
          cursor: not-allowed;
          &:hover {
            color: ${theme.colors.grey[800]};
            text-decoration: none;
          }
          &:active {
            border: none;
          }
        `
      : isActive 
        ? activeStyles
        : hoverStyles
};
    /* ${({ isActive }) => isActive ? activeStyles : hoverStyles}; */
`;

export const ElText = styled.div`
  max-height: 44px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LogoContainer = styled.div`
  padding-top: 21px;
  img {
    width: ${props => (props.isOpen ? '80px' : '50px')};
    transition: width 0.3s;
  }
`;

export const CollapseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; 
  height: 100%;
  width: 100%;
`;

export const CollapseWrapperItem = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center; 
  height: 36px;
  width: 30px;
  cursor: pointer;
  padding: 0 10px 20px 0;

  img {
    transform: ${({ isOpen }) =>  isOpen ? "rotate(0eg)" : "rotate(180deg)"};
    transition: transform .5s ease-in-out;
  }
`;