import React from 'react';
import PropTypes from 'prop-types';
import backendTranslation from '../../utils/backendTranslation';

import AlertIcon from '../../../assets/img/alert.svg';

const CustomDescriptionField = ({ description, infoBoxMsg }) => {
  return (
    <>
      <p className='field-description'>{description}</p>
      {
        infoBoxMsg &&
          <div className='field-description-infobox'>
            <img src={AlertIcon} alt="Alert" />
            <p>{backendTranslation(infoBoxMsg)}</p>
          </div>
      }
    </>
  );
};

CustomDescriptionField.propTypes = {
  description: PropTypes.string,
  infoBoxMsg: PropTypes.objectOf(PropTypes.string)
};

export default CustomDescriptionField;