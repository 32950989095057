import React from 'react';
import PropTypes from 'prop-types';

const JobGroupActions = ({ selection }) => {
  return (
    <div className="position-absolute">TODO: group actions (for {selection.length})</div>
  )
};

JobGroupActions.propTypes = {
  selection: PropTypes.array,
}

export default JobGroupActions;
