import styled, { css, keyframes } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { theme } from '../../theme';

const fadeIn = keyframes`
  0% {
    opacity: 0.1;
  }

  100% {
    opacity: 1;
  }
`;

export const ContainerJob = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 876px;
  padding: 0 20px;
  margin: 0 auto;

  @media(min-width: 1441px){
    width: 1168px;
    max-width: 1168px;
  };
`;

export const Breadcrumbs = styled.div`
    height: 48px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    gap: 16px;
    color: ${theme.colors.primary[500]};

    a:nth-of-type(2), a:nth-of-type(2):hover {
        color: ${theme.colors.black};
    }

    a:hover {
        text-decoration: none;
        color: ${theme.colors.primary[500]};
    }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Up = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const UpButton = styled.a`
  padding: 6px 12px;
  background: ${theme.colors.white};
  box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  border-radius: 4px;
  font-weight: ${theme.fontWeight.medium};
  font-size: ${theme.fontSize.small};
  line-height: 20px;
  letter-spacing: 0.28px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 8px;
  color: ${theme.colors.grey[700]};
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: none;
    color: ${theme.colors.grey[900]};
    box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
  &:active {
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
`;

export const ContainerImg = styled.div`
  display: flex;
  align-items: center;
`;

export const UpText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  height: 32px;
  color: ${theme.colors.grey[500]};
`;

export const Link = styled(RouterLink)`
  &:hover {
    text-decoration: none;
  }
`;

export const HeaderText = styled.div`
  font-size: ${theme.fontSize.xxLarge};
  font-weight: ${theme.fontWeight.bold};
  line-height: 32px;
  padding-right: 10px;
  color: ${theme.colors.black};
`;

export const Down = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  height: 38px;
`;

export const ContainerButtons = styled.div`
  display: flex; 
  gap: 10px; 
  align-items: center;
`;

export const Tabs = styled.div`
  display: flex;
  width: 100%;
  padding-top: 15px;
`;

export const TabsEl = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 14px;
  width: calc(100% / 3);
  height: 54px;
  padding-bottom: 16px;
  border-bottom: 1px solid ${theme.colors.grey[50]};
  font-size: ${theme.fontSize.medium};
  line-height: 20px;
  font-weight: ${theme.fontWeight.medium};
  color: ${theme.colors.grey[700]};
  cursor: pointer;
  
  ${({ disabled }) => disabled ? `
      color: ${theme.colors.grey[400]};
      cursor: not-allowed;
    `
    :
    ` 
    &:hover {
      border-bottom: 5px solid #5E5ADB;
      padding-bottom: 12px;
      font-weight: ${theme.fontWeight.bold};
    };
  `}

  ${({ active }) => active && css`
    border-bottom: 5px solid #5E5ADB;
    padding-bottom: 12px;
    font-weight: ${theme.fontWeight.bold};
  `}
`;

export const Error = styled.div`
  width: 100%;
  padding: 16px;
  margin-top: 14px;
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  background-color: ${theme.colors.error};
  font-weight: ${theme.fontWeight.medium};
  animation: ${fadeIn} 1.2s forwards;
`;

export const ErrorBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ErrorTitle = styled.div`
  line-height: 20px;
  letter-spacing: 0.28px;
  font-size: ${theme.fontSize.medium};
  color: ${theme.colors.grey[600]};
`;

export const ErrorText = styled.div`
  line-height: 18px;
  letter-spacing: 0.36px;
  font-size: ${theme.fontSize.small};
  color: ${theme.colors.grey[500]};
`;

export const JobName = styled.div`
  color: ${theme.colors.primary[500]};
`;