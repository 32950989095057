import styled from 'styled-components';

import { theme } from '../theme';

export const ExternalFormIframe = styled.iframe`
  border-radius: 12px;
  border: 1px solid ${theme.colors.grey['100']};
`;

export const IframeContainer = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 50vh;
  width: 100%;
  height: 75vh;

  iframe {
    border: 0;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  ${({ isFullScreen }) => isFullScreen && `
    position: fixed;
    width: 100%;
    height: calc(100vh) !important;
    top: 0;
    left: 0;
    z-index: 1024;

    iframe {
      height: 100vh !important;
    }
  `}
`;

export const IframeButton = styled.button`
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 1000;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;