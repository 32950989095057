import React from 'react';
import '../../styles/taskButton.scss';
import PropTypes from 'prop-types';
const Button = ({ icon, text, onClick, style }) => {
  return (
    <button className="taskButton" onClick={onClick} style={style}>
      { icon && <img className="taskImg" src={icon} alt="icon"/>}
      {text}
    </button>
  );
};
export default Button;

Button.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object
};