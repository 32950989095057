import styled, { css } from 'styled-components';
import { theme } from '../theme';

export const AppWrapper = styled.div`
  display: grid; 
  transition: grid-template-columns 0.3s ease-in-out;
  grid-template-columns: ${({ isOpen }) => 
    isOpen ? "186px 1fr" : "72px 1fr"};
`;

export const MainContent = styled.div`
  height: calc(100vh - 56px);
`;

export const Hr = styled.div`
  width: ${({ isOpen }) =>  isOpen ? "100%" : "50px"};
  transition: width .3s linear;
  height: 0.5px;
  background-color: ${theme.colors.grey[100]};
  margin: 20px 0;
`;

export const NormalColumn = styled.div`
  ${({ fullHeight, center, autoHeight }) => css`
    ${fullHeight && 'height: 100%;'};
    ${center && "align-items: center;"};
    @media (max-width: ${autoHeight}px) {
      height: auto;
    }
  `}
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const NormalRow = styled.div`
  ${({ fullHeight, center }) => css`
    ${fullHeight && 'height: 100%;'};
    ${center && 'justify-content: center;'};
  `};
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`;