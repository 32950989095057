import styled from 'styled-components';
import { Button } from 'reactstrap';
import { theme } from '../theme';

export const CustomButton = styled(Button)`
  ${({ withoutText, onlyIcon }) => 
    !withoutText && !onlyIcon && `
      display: flex;
      align-items: center;
      gap: 7px;
    `};
  ${({ withoutText }) => 
    withoutText && `
      box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
      width: 24px;
      height: 24px;
      padding: 0;
      border-radius: 4px;
      transition: .2s all;
      margin: 0;
      color: ${theme.colors.grey["400"]};
      > :first-child {
        margin-bottom: 1.5px;
        color: ${theme.colors.grey["400"]};
      }

      &:hover {
        box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
        color: ${theme.colors.grey["400"]};
      }
      
      &:active {
        box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
      }

      &:disabled {
        background-color: #F7F9FC;
        box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.20);
        > :first-child {
          color: ${theme.colors.grey["400"]};
        }
      }
    `};
  ${({ onlyIcon }) => 
    onlyIcon && `
      padding: 0;
      border-radius: 4px;
      transition: .2s all;
      margin: 0;

      > :first-child {
        margin-bottom: 1.5px;
        color: initial;
      }

      &:hover {       
        > :first-child {
          color: red;
        }
      }
    
      &:disabled {
        > :first-child {
          color: #868FA0;
        }
      }
    `};
`;
