/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { MdCancel } from 'react-icons/md';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import * as notify from '../../utils/notify';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import backendTranslation from '../../utils/backendTranslation';
import { CustomButton } from '../../styles/styled-components/taskButton';

@ErrorBoundary((props) => {
  return `${props.t('TaskCanceller')}`;
})
@withTranslation()
@withSendRequest

export default class TaskCanceler extends Component {

  cancel = () => {
    const { t } = this.props;
    this.props.sendRequest('post', '/jobs/' + this.props.taskId + '/cancel/')
      .then(response => {
        //Canceling operation successfully started
        notify.success(
          t('job_actions.notifications.cancel.success_notification_title'),
          t('job_actions.notifications.cancel.success_notification_body', { id: this.props.taskId })
        );
        this.props.refresh();
      })
      .catch(function (error) {
        //Canceling failed
        notify.error(
          t('job_actions.notifications.cancel.error_notification_title'),
          error?.response ? backendTranslation(error?.response.data) : error?.message
        );
        throw error;
      });
  };

  render() {
    const { loading, t, withoutText, onlyIcon } = this.props;
    const allowStates = [ 'PENDING', 'EXECUTING' ];
    const disabled = loading || !allowStates.includes(this.props.currentState) ;
    
    return (
      <CustomButton
        size="sm"
        onClick={this.cancel}
        disabled={disabled}
        className="task-canceler"
        color={withoutText ? "white" : "danger"}
        withoutText={withoutText}
        onlyIcon={onlyIcon}
      >
        <MdCancel />
        { !withoutText && !onlyIcon && t('cancel_job') }
      </CustomButton>
    );
  }
}

TaskCanceler.propTypes = {
  taskId: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  refresh: PropTypes.func.isRequired,
  currentState: PropTypes.string,
  withoutText: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
};
