import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation  } from 'react-router-dom';
import qs from 'qs';

import { saveToQuery } from '../jobTableUtils';

const TextFilter = ({ column_id }) => {
  let location = useLocation();

  const value = useMemo(()=> {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    return query ? query[column_id] : undefined;
  }, [ location ]);

  const onValueChange = useCallback(event => {
    saveToQuery({
      [column_id]: event.target.value || null,
      page: 1,
    })
  }, []);

  return (
    <input defaultValue={value} onChange={onValueChange}/>
  )
}

TextFilter.propTypes = {
  column_id: PropTypes.string.isRequired,
};

export default TextFilter;
