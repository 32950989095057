import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { 
  AmountofTags, 
  CollapseText, 
  FilterContainer, 
  FilterTitle, 
  Icon, 
  SearchContainer, 
  SearchInput, 
  SortBy, 
  SortByContainer, 
  Tags 
} from '../../styles/styled-components/newTemplates';
import searchIcon from '../../../assets/img/templates/search-icon.svg';
import { Arrow, CustomButton } from '../../styles/styled-components/jobs/Results';
import { Collapse } from 'reactstrap';

const TemplateFilter = ({ isOpen }) => {
  const [ isActive, setIsActive ] = useState(true);

  return (
    <FilterContainer fullHeight isOpen={isOpen}>
      <FilterTitle>???Filter with tags</FilterTitle>
      <Tags>???124 tags discovered</Tags>
      <SearchContainer style={{ width: "222px", padding: "20px 0" }}>
        <Icon src={searchIcon} alt="search" />
        <SearchInput 
          type="text" 
          placeholder="???Wyszukaj zadania..."
        />
      </SearchContainer>
      <CustomButton onClick={() => setIsActive(!isActive)}>
        <CollapseText>Sort by</CollapseText>
        <Arrow active={isActive}/>
      </CustomButton>
      <Collapse isOpen={isActive}>
        <SortByContainer>
          { [ ...Array(30) ].map(arr => 
            <SortBy key={arr}>
              <div>???Nazwa kategorii</div>
              <AmountofTags>???144</AmountofTags>
            </SortBy>  
          )}
        </SortByContainer>
      </Collapse>
    </FilterContainer>
  );
};

export default TemplateFilter;

TemplateFilter.propTypes = {
  isOpen: PropTypes.func
};