import styled from 'styled-components';

export const BadgeCustom = styled.div`
  display: inline-flex;
  gap: .5rem;
  padding-left: 0.4rem;
  padding-right: 8px;
  border-radius: 4px;
  font-size: .75rem;
  height: 1.25rem;
  align-items: center;
`;

export const BadgeCustomIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  & > svg {
      height: 12px;
      width: 12px;
      & > path {
        width: 100% !important;
        height: 100% !important;
      }
    }
`;