/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import i18n from '../../main/utils/i18nConfigProvider';

export const getCurrentLanguageShortCode = () => {
  return i18n.language.substring(0, 2).toLowerCase();
};