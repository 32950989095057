import React from 'react';
import { Button } from 'reactstrap';

import { __env } from '../../../envloader';

const BrowseDataButtons = () => (
  <div className="center">
    {
      __env.SUBMIT_FORM_FILE_SELECTOR_PROVIDERS.filter(ele => ele.type === 'url').map(ele => (
        <a key={ele.label} href={ele.url} target='__blank' className="mb-2 mx-2">
          <Button>
            {ele.label}
          </Button>
        </a>
      ))
    }
  </div>
);

export default BrowseDataButtons;