/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { BadgeCustom, BadgeCustomIcon } from '../styles/styled-components/badgeCustom';
import { Success, Error, Executing, Cancel, Submited } from '../../assets/img/task/icons';

@ErrorBoundary((props) => {
  return `${props.t('job_state')}`;
})
@withTranslation()
export default class TaskState extends Component {

  mapStateToColor = state => {
    switch (state) {
    case 'SUBMITTED': return { color: '#5E5ADB', background: '#EDEDFC', icon: Submited };
    case 'EXECUTING': return { color: '#106BEF', background: '#E7F0FD', icon: Executing };
    case 'FINISHED': return { color: '#189075', background: '#D8F7EF', icon: Success };
    case 'FAILED': return { color: '#D1293D', background: '#FFEDEF', icon: Error };
    case 'CANCELED': return { color: '#687182', background: '#E9EDF5', icon: Cancel };
    default: return { color: 'light' };
    }
  };

  render() {
    const attributes = this.mapStateToColor(this?.props?.state);
    return this.props.state ? (    
      <BadgeCustom 
        style={{
          color: attributes.color,
          backgroundColor: attributes.background,
        }}
      > 
        { attributes.icon && 
          <BadgeCustomIcon>
            <attributes.icon />
          </BadgeCustomIcon>
        }
        <span style={{ lineHeight: "1em" }}>
          {
            this.props.isDetailState
              ? this.props.state
              : this.props.t([ `major_states.${this.props.state}`, this.props.state ])
          }
        </span>
      </BadgeCustom>
    ) : null;
  }
};

TaskState.propTypes = {
  state: PropTypes.string,
  className: PropTypes.string,
  isDetailState: PropTypes.bool,
  t: PropTypes.func, //HOC
};
