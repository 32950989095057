import React from 'react';
import { Redirect } from 'react-router';
import { getLS, removeLS } from '../../main/utils/localStorageProvider';

const BackFromProfile = () => {
  const defaultGrantLS = getLS('grant_default');
  removeLS('grant_default');

  return (
    <Redirect
      to={`/ui/tasks${
        defaultGrantLS !== null
          ? "?grant_default=" + defaultGrantLS + "&grant=" + defaultGrantLS
          : ""
      }`}
    />
  );
};

export default BackFromProfile;
