import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory  } from 'react-router-dom';
import { Collapse } from 'reactstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Trans, useTranslation } from 'react-i18next';

import { 
  CollapseText,
  IconContainer,
  NewJob,
  TemplateContainer, 
  TemplatesContainer, 
  TemplateMain, 
  TemplateTitle, 
  TemplateDescription,
  AlertContainer,
  CommunicatContainer,
  TitleComunicat,
  BodyComunicat, 
} from '../../styles/styled-components/newTemplates';
import TemplateIcon from '../../../assets/img/templates/template-icon';
import { getCurrentLanguageShortCode } from '../../utils/templates';
import { Arrow, CustomButton } from '../../styles/styled-components/jobs/Results';
import { AllTemplatesIcon, NoTemplates } from '../../../assets/img/templates/icons.js';

const AllTemplates = ({ isFilter, search, setActiveUrl, templates, userAccountId }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const shortLngCode = getCurrentLanguageShortCode();

  const [ isActive, setIsActive ] = useState(true);

  const compareTemplates = (a, b) => {
    const nameA = a[1]?.get('name')?.toUpperCase() || '';
    const nameB = b[1]?.get('name')?.toUpperCase() || '';
    
    return nameA.localeCompare(nameB);
  };

  const allArrays = templates?.size > 0 && templates
    .entrySeq()
    .sort(compareTemplates)
    ._array.filter((array) =>
      array[1].get("name").toLowerCase().includes(search)
    )
    .map(([ key, item ]) => {
      return Object.values([ key, item ]);
    });

  const showTemplateDetails = (url) => {
    setActiveUrl(url);
    history.push(`/ui/templates?details${userAccountId ? `&userAccountId=${userAccountId}` : ''}`);
  };

  return (
    <>
      <CustomButton onClick={() => setIsActive(!isActive)}>
        <img src={AllTemplatesIcon} alt="AllTemplatesIcon"/>
        <CollapseText>{t('new_templates.all_templates')}</CollapseText>
        <Arrow active={isActive}/>
      </CustomButton>
      {allArrays ?
        <Collapse isOpen={isActive}>
          <TransitionGroup>
            <TemplatesContainer scroll>
              {allArrays.map(template => (
                <CSSTransition
                  key={template[0]}
                >
                  <TemplateContainer isFilter={isFilter}>
                    <IconContainer onClick={() => showTemplateDetails(template[0])}>
                      {template[1].get('icon_url') 
                        ? <img src={template[1].get('icon_url')} alt="template icon" className="mw-50 mh-75"/> 
                        : <TemplateIcon alt="" className="mw-50 mh-75"/>
                      }  
                    </IconContainer>
                    <TemplateMain>
                      <TemplateTitle onClick={() => showTemplateDetails(template[0])}>
                        {template[1].get('name')}
                      </TemplateTitle>
                      <TemplateDescription>
                        {template[1].getIn([ 'description', shortLngCode ])}
                      </TemplateDescription>
                    </TemplateMain>
                    <NewJob disabled={!userAccountId} to={userAccountId && `/ui/submit?templateUrl=${template[0]}&userAccountId=${userAccountId}`}>
                      {t('new_templates.submit_job')}
                    </NewJob>
                  </TemplateContainer>
                </CSSTransition>
              ))}
            </TemplatesContainer>
          </TransitionGroup>
        </Collapse>
        :
        <Collapse isOpen={isActive}>
          <AlertContainer>
            <img src={NoTemplates} alt="No Templates"/>
            <CommunicatContainer>
              <TitleComunicat>{t('new_templates.no_templates')}</TitleComunicat>
              <BodyComunicat>
                <Trans i18nKey="new_templates.no_templates_body">
                  Nie masz jeszcze szablonu. 
                  <br/>Przepraszamy za niedogodność, pracujemy nad nowym
                </Trans>
              </BodyComunicat>
            </CommunicatContainer>
          </AlertContainer>
        </Collapse>
      }
    </>
  );
};

export default AllTemplates;

AllTemplates.propTypes = {
  isFilter: PropTypes.bool,
  search: PropTypes.string, 
  setActiveUrl: PropTypes.func,
  templates: PropTypes.object,
  userAccountId: PropTypes.number
};