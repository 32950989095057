/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import i18n from 'i18next';
import XHR from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { __env } from '../../envloader';
import ibis_ui_en from '../../ibis-ui/public/locales/en/ibis-ui.json';
import ibis_ui_pl from '../../ibis-ui/public/locales/pl/ibis-ui.json';


i18n
  .use(XHR)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    backend: {
      loadPath: __env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json'
    },
    // have a common namespace used around the full app
    ns: [ 'common', 'qcg-ui', 'ibis-ui', 'richTextTranslations' ],
    defaultNS: 'qcg-ui',
    saveMissing: true,
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
      console.warn('Missing key: ' + key + '\nLang: [' + lng + ']\nNamespace: [' + ns + ']');
    },

    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    },
    react: {
      bindI18n: 'languageChanged loaded',
    }
  });

i18n.on('languageChanged', function(lng) {
  document.documentElement.lang = lng;
  document.title = i18n.t('app_title');
});

i18n.addResourceBundle('en', 'ibis-ui', ibis_ui_en);
i18n.addResourceBundle('pl', 'ibis-ui', ibis_ui_pl);

export default i18n;