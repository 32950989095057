import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { MdCancel } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CancelingActionModal from '../tasks/cancelingActionModal';
import TaskRemover from '../tasks/taskRemover';

const JobTableRowActions = ({ row }) => {
  const { t } = useTranslation();
  const [ cancelModal, setCancelModal ] = useState(false);
  return (
    <>
      <div className="d-flex">
        <Button
          outline
          onClick={() => setCancelModal(true)}
          title={t('cancel_job')}
        >
          <MdCancel />
        </Button>
        <TaskRemover
          taskId={row.original.id}
          onSuccess={() => setCancelModal(false)}
        />
      </div>
      {
        cancelModal &&
          <CancelingActionModal
            idsArray={[ row.original.id ]}
            onClose={() => setCancelModal(false)}
          />
      }
    </>
  );
};


JobTableRowActions.propTypes = {
  row: PropTypes.object,
};

export default JobTableRowActions;
