/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';

import { withSendRequest } from '../../../main/hoc/withSendRequest';
import * as notify from '../../utils/notify';
import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import backendTranslation from '../../utils/backendTranslation';
import ConfirmationModal from '../../components/confirmationModal';
import { CustomButton } from '../../styles/styled-components/taskButton';

@ErrorBoundary((props) => {
  return `${props.t('TaskRemover')}`;
})
@withTranslation()
@withSendRequest
class TaskRemover extends Component {
  state = {
    isModalOpen: false,
  };

  toggleModal = () => {
    this.setState(state => ({ isModalOpen: !state.isModalOpen }) );
  };

  remove = () => {
    const { t } = this.props;
    this.props.sendRequest('delete', '/jobs/' + this.props.taskId + '/')
      .then(() => {
        //removing operation successfully started
        notify.success(
          t('job_actions.notifications.remove.success_notification_title'),
          t('job_actions.notifications.remove.success_notification_body', { id: this.props.taskId })
        );
        //TODO: Remove timeout when backend operation will be synchronous
        if (this.props.onSuccess) {
          this.props.onSuccess();
        }
        else {
          this.props.history.push('/ui/tasks');
        } 
      })
      .catch(error => {
        //removing failed
        notify.error(
          t('job_actions.notifications.remove.error_notification_title'),
          error?.response ? backendTranslation(error?.response.data) : error?.message
        );
        throw error;
      });
  };

  render() {
    const { t, withoutText, onlyIcon } = this.props;
    return (
      <>
        {
          this.state.isModalOpen &&
            <ConfirmationModal
              header={t("job_actions.remove_confirmation.title")}
              body={t("job_actions.remove_confirmation.body", { id: this.props.taskId })}
              onClick={this.remove}
              onClose={this.toggleModal}
            />
        }
        <CustomButton
          size="sm"
          onClick={this.toggleModal}
          className="task-remover"
          color={withoutText || onlyIcon ? "white" : "danger"}
          title={t('remove_job')}
          withoutText={withoutText}
          onlyIcon={onlyIcon}
        >
          <FaTrashAlt />
          {!withoutText && !onlyIcon && <span>{t('remove_job')}</span>}
        </CustomButton>
      </>
    );
  }
}
export default withRouter(TaskRemover);

TaskRemover.propTypes = {
  taskId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  withoutText: PropTypes.bool,
  onlyIcon: PropTypes.bool,
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};
