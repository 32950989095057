
const namespace = '@QCG_INACTIVE_ACCOUNTS/';

export const ADD_INACTIVE_ACCOUNTS = `${namespace}ADD_INACTIVE_ACCOUNTS`;
export const FINISH_INACTIVE_ACCOUNTS_LOADING = `${namespace}FINISH_INACTIVE_ACCOUNTS_LOADING`;

export const addInactiveAccounts = id => ({
  type: ADD_INACTIVE_ACCOUNTS,
  payload: id
})

export const finishLoadingAccounts = () => ({
  type: FINISH_INACTIVE_ACCOUNTS_LOADING,
  payload: false
})