import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Spinner } from 'reactstrap';
import PropTypes from 'prop-types';

import {
  Container,
  SpaceSpinner,
  Alert,
  Text,
  IframeButton,
  IframeContainer
} from '../../styles/styled-components/jobs/Monitoring';
import backendTranslation from '../../utils/backendTranslation';
import { FullScreen, MinScreen } from '../../../assets/img/task/icons';

@withTranslation()
export default class Monitoring extends Component {
  state = {
    isLoadingSpinner: true,
    isFullScreen: false,
  };
  
  toggleIframeSize = () => {
    this.setState(prevState => ({
      isFullScreen: !prevState.isFullScreen
    }));
  };

  render () {
    const { isLoadingSpinner, isFullScreen } = this.state;
    const { graphes, isLoadingGraphes, url } = this.props;
    const [ urlWithoutQuery, query ] = url?.includes('?') 
      ? url.split('?') 
      : [ url, '' ];
    const newQuery = new URLSearchParams(query);
    newQuery.set('lng', this.props.i18n.language);
    const finalUrl =`${urlWithoutQuery}?${newQuery.toString()}`;
    const hideSpinner = () => {
      this.setState({ isLoadingSpinner: false });
    };

    return (
      <Container>   
        {
          isLoadingGraphes ?
            <SpaceSpinner>
              <Spinner color="primary" />
            </SpaceSpinner>
            : 
            (graphes?.panels?.length > 0 ?
              graphes.panels.map((url, index) =>
                <div className="iframe-container">
                  <iframe title={`graph-${index}`} src={url}></iframe>
                </div>
              )
              : graphes?.size !== 0 &&            
                <Alert style={{ margin: "50px 0" }}>
                  <Text>{backendTranslation(graphes)}</Text>
                </Alert>
            )
        }
        { url && isLoadingSpinner &&
          <SpaceSpinner>
            <Spinner color="primary"/>
          </SpaceSpinner>
        }
        { url &&
          <IframeContainer isFullScreen={isFullScreen}>
            <IframeButton onClick={this.toggleIframeSize} id="toggleButton">
              {isFullScreen ? <img src={MinScreen} alt="MinScreen"/> : <img src={FullScreen} alt="FullScreen"/>}
            </IframeButton>
            <iframe title={this.props.title} src={finalUrl} onLoad={hideSpinner}></iframe>      
          </IframeContainer>
        }
        { !url &&
          <Alert>
            <Text>{this.props.t(`task.alert`)}</Text>
          </Alert>
        }
        
      </Container>
    );
  }
};

Monitoring.propTypes = {
  graphes: PropTypes.oneOfType([ PropTypes.array, PropTypes.object ]),
  isLoadingGraphes: PropTypes.bool,
  i18n: PropTypes.object, //HOC
  jobId: PropTypes.number,
  url: PropTypes.string,
  t: PropTypes.func, //HOC
  title: PropTypes.string
};