/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

const namespace = '@QCG_TASKGRID/';

export const CHANGE_COLUMNS = namespace + "CHANGE_COLUMNS";

export function changeColumns({ columns }) {
  return {
    type: CHANGE_COLUMNS,
    payload: { columns }
  };
}
