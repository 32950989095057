import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { ButtonGroup , Collapse, UncontrolledTooltip } from 'reactstrap';
import moment from 'moment';

import Button from '../tasks/button';
import { Download } from '../../../assets/img/task/icons';
import { 
  Details, 
  DetailsUp, 
  DetailsUpRow, 
  DetailsUpRowTitle, 
  DetailsUpRowText, 
  DetailsDown, 
  DetailsDownRow, 
  DetailsDownRowTitle,
  History,
  HistoryRow,
  HistoryText
} from '../../styles/styled-components/jobs/OverView';
import TaskState from '../../components/taskState';
import { formatDate } from '../../utils/tasksgrid';
import { Arrow, CustomButton } from '../../styles/styled-components/jobs/Results';
import * as notify from '../../utils/notify';


@withTranslation()
export default class Overview extends Component {
  state = {
    activeAdvanced: false,
    activeHistory: false
  };

  renderUpTask = (title, value, id) => {
    return (
      <DetailsUpRow id={id}>
        <DetailsUpRowTitle>{this.props.t(title)}:</DetailsUpRowTitle>
        <DetailsUpRowText>{value}</DetailsUpRowText>
      </DetailsUpRow>
    );
  };

  renderDownTask = (title, value) => {
    return (
      <DetailsDownRow>
        <DetailsDownRowTitle>{this.props.t(title)}:</DetailsDownRowTitle>
        {this.props.data?.get(value)}
      </DetailsDownRow>
    );
  };

  activeButtonAdvanced = () => {
    this.setState({ activeAdvanced: !this.state.activeAdvanced });
  };

  activeButtonHistory= () => {
    this.setState({ activeHistory: !this.state.activeHistory });
  };

  formatTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp);
    return `${dateObject.toLocaleDateString()} ${dateObject.toLocaleTimeString()}`;
  };
  
  handleCopy = (password) => {
    if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") { //It only works for the https path
      navigator.clipboard.writeText(password)
        .then(() => {
          notify.success("", this.props.t('task.copy_success'));
        })
        .catch(() => {
          notify.error("", this.props.t('task.copy_error'));
        });
    } 
    else {
      notify.error("", this.props.t('task.copy_error'));
    }
  };

  render() {
    const { data, history } = this.props;
    const { activeAdvanced, activeHistory } = this.state;
    const startTime = data.get('start_time');
    const finishTime = data.get('finish_time');
    const completingTime = history?.find(r => r.get('state') === "COMPLETING")?.get('created');

    const calculationTime = () => {
      if (data){
        const now = moment();
        const start_time = moment(startTime);
        const finish_time = moment(finishTime);
        const duration = finish_time.isValid() 
          ? moment.duration(finish_time.diff(start_time)) 
          : completingTime && history
            ? moment.duration(completingTime.diff(start_time))
            : moment.duration(now.diff(start_time));

        const { days, hours, minutes, seconds } = {
          days: duration.days(),
          hours: duration.hours(),
          minutes: duration.minutes(),
          seconds: duration.seconds(),
        };
        
        const timeString = [
          days && `${days}d`,
          hours && `${hours}h`,
          minutes && `${minutes}min`,
          seconds && `${seconds}s`,
        ].filter(Boolean).join(' ');
       
        return (timeString);
      }
    };

    return (
      <Details>
        <DetailsUp>
          {this.renderUpTask(`task.template`, data.get('template_name'))}
          {this.renderUpTask(`site`, data.getIn([ 'useraccount', 'site_name' ]))}
          {this.renderUpTask(`task.order_time`, (require('moment')(data.get('submit_time'))).fromNow(), "CalculationTimeTooltip")}
          <UncontrolledTooltip style={{ whiteSpace: "nowrap", maxWidth: "none", width: "auto" }} placement="bottom-start" target="CalculationTimeTooltip">
            {this.formatTimestamp(data.get('submit_time'))}
          </UncontrolledTooltip>
          {startTime && this.renderUpTask(`task.computational_time`, calculationTime())}
          {this.props.calculations && Object.keys(this.props.calculations).length > 0 &&
            <DetailsUpRow>
              <DetailsUpRowTitle>{this.props.t(`task.calculations`)}:</DetailsUpRowTitle>
              {Object.entries(this.props.calculations).map(([ key, value ]) => 
                <ButtonGroup>
                  <Button
                    key={key}
                    text={key}
                    icon={Download}
                    onClick={() => window.open(value?.url, "_blank")}
                    style={{ borderRadius: "4px 0 0 4px" }}
                  />  
                  <Button 
                    onClick={() => this.handleCopy(value?.secret)} 
                    text={this.props.t('task.copy_password')}
                    style={{ borderRadius: "0 4px 4px 0" }}
                  />
                </ButtonGroup>
                
              )}                
            </DetailsUpRow>
          }
          {/* <DetailsUpRow>
            <DetailsUpRowTitle>{this.props.t(`task.results`)}:</DetailsUpRowTitle>
            <Button text="Otwórz" icon={Download}/>
            <Button text="Wizualizuj" icon={Download}/>
            <Button text="Kopiuj link" icon={Link}/>
          </DetailsUpRow> */}
        </DetailsUp>
 
        <CustomButton onClick={this.activeButtonAdvanced}>
          {this.props.t(`task.advanced`)}
          <Arrow active={activeAdvanced}/>
        </CustomButton> 
        <Collapse isOpen={!activeAdvanced}>
          <DetailsDown>
            {this.renderDownTask(`task.task_ID`, 'id')}
            {this.renderDownTask(`task.requesting_User_D`, 'user')}
            {this.renderDownTask(`task.template`, 'template_name')}
            {this.renderDownTask(`task.template_Version`, 'template_version')}
            {this.renderDownTask(`task.grant`, 'grant')}
            {this.renderDownTask(`task.current_Queue`, 'queue')}
          </DetailsDown>
        </Collapse>

        { this.props.history &&
          <>
            <CustomButton onClick={this.activeButtonHistory}>
              {this.props.t(`task.history`)}
              <Arrow active={activeHistory}/>
            </CustomButton> 
            <Collapse isOpen={!activeHistory}>
              <History>
                {  this.props.history.map(record =>
                  <HistoryRow key={record.get('created')}>
                    <HistoryText>{formatDate(record.get('created'), false, record.get('event_id') + '_created')}</HistoryText>
                    <td><TaskState state={record.get('state')} isDetailState/></td>
                  </HistoryRow>
                )
                }
              </History> 
            </Collapse>
          </>
        }
      </Details>
    );
  }
};

Overview.propTypes = {
  jobId: PropTypes.number,
  t: PropTypes.func, //HOC
  data: PropTypes.object,
  history: PropTypes.object,
  calculations: PropTypes.object
};