import styled, { keyframes } from 'styled-components';


const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const Spinner = styled.div`
  position: relative;
  width: 36px;
  height: 36px;
  background: conic-gradient(#0000 10%, #5E5ADB);
  border-radius: 50%;
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 5px), #000 0);
  animation: ${spin} 1s infinite linear;
`;
