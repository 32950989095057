/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import moment from 'moment';
import { dataLength } from '../utils/charts';

const namespace = '@QCG_CHARTS/';

export const LOAD_QUEUES_TASK_COUNT_DATA = namespace + "LOAD_QUEUES_TASK_COUNT_DATA";
export const LOAD_WEEK_INTERVAL_DATA = namespace + "LOAD_WEEK_INTERVAL_DATA";
export const LOAD_DAY_INTERVAL_DATA = namespace + "LOAD_DAY_INTERVAL_DATA";
export const LOAD_HOUR_INTERVAL_DATA = namespace + "LOAD_HOUR_INTERVAL_DATA";
export const LOAD_MINUTE_INTERVAL_DATA = namespace + "LOAD_MINUTE_INTERVAL_DATA";

export const loadChartsData = ({ sendRequest }) => dispatch => {
  sendRequest("get", "/jobs/search/?state=PENDING&facet=queue")
    .then(response => {
      dispatch({
        type: LOAD_QUEUES_TASK_COUNT_DATA,
        payload: response.data.facets.queue
      });
    });
  sendRequest("get", "/jobs/search/?stats=events&stats_interval=week&submit_time_from=" +
    moment().startOf('isoWeek').toISOString() +
    "&submit_time_to=" + moment().toISOString())
    .then(response => {
      dispatch({
        type: LOAD_WEEK_INTERVAL_DATA,
        payload: response.data.stats.events
      });
    });
  sendRequest("get", "/jobs/search/?stats=events&stats_interval=day&submit_time_from=" +
    moment().subtract(dataLength - 1, 'day').toISOString() +
    "&finish_time_to=" + moment().toISOString())
    .then(response => {
      dispatch({
        type: LOAD_DAY_INTERVAL_DATA,
        payload: response.data.stats.events
      });
    });
  sendRequest("get", "/jobs/search/?stats=events&stats_interval=hour&submit_time_from=" +
    moment().subtract(dataLength - 1, 'hour').toISOString() +
    "&finish_time_to=" + moment().toISOString())
    .then(response => {
      dispatch({
        type: LOAD_HOUR_INTERVAL_DATA,
        payload: response.data.stats.events
      });
    });
  sendRequest("get", "/jobs/search/?stats=events&stats_interval=minute&submit_time_from=" +
    moment().subtract(dataLength - 1, 'minute').toISOString() +
    "&finish_time_to=" + moment().toISOString())
    .then(response => {
      dispatch({
        type: LOAD_MINUTE_INTERVAL_DATA,
        payload: response.data.stats.events
      });
    });
};
