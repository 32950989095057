import React from 'react';

import { Spinner, SpinnerContainer } from '../../qcg-ui/styles/styled-components/Loading';

const loading = () => {
  return (
    <SpinnerContainer>
      <Spinner />
    </SpinnerContainer>
  );
};

export default loading;
