/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { fromJS, Map } from 'immutable';
import { withRouter, Link } from 'react-router-dom';

import { FaChevronLeft } from 'react-icons/fa';
import { withSendRequest } from '../../../main/hoc/withSendRequest';
import SubmitForm from './submitForm';
import { getCurrentLanguageShortCode } from '../../utils/templates';
import SubmitSuccessModal from './submitSuccessModal';
import ExternalForm from './externalForm';
import * as notify from '../../utils/notify';
import backendTranslation from '../../utils/backendTranslation';
import axiosInstance from '../../../main/utils/axios/axiosInstance';

@withTranslation()
@withSendRequest
class Submit extends Component {
  state = {
    templateData: Map(),
    loading: false,
    successId: undefined,
    external_gui_url: undefined
  };

  getTemplateURL = (parameter) => {
    //TODO: import url-search-params-polyfill for IE and Opera mini
    const query = new URLSearchParams(this.props.location.search);
    return query && query.get(parameter);
  };

  getTemplateData = () => {
    const templateUrl = this.getTemplateURL('templateUrl');
    if (templateUrl) {
      this.setState({ templateData: Map(), loading: true });
      const shortLngCode = getCurrentLanguageShortCode();
      const userAccountId = this.getTemplateURL('userAccountId');

      axiosInstance({
        url: `/templates/render/?templateUrl=${templateUrl}&locale=${shortLngCode}${userAccountId ? `&userAccountId=${userAccountId}` : ''}`,
        method: "get",
        __silentFor: [ { status: 400 } ] 
      })
        .then(response => {
          this.setState({ templateData: fromJS(response.data.templateData), loading: false });
        })
        .catch(error => {
          notify.error(this.props.t('template_error'), error?.response ? backendTranslation(error?.response.data) : error?.message);
          this.setState({ loading: false });
        });
    }
  };

  submit = ({ formData }) => {
    axiosInstance.post(
      `/jobs/`,
      { template_source: this.state.templateData.get('source').toJS(), template_vars: formData, useraccount_id: this.getTemplateURL('userAccountId') }
    )
      .then(response => {
        this.setState({ successId: response.data.id });
      })
      .catch(error => {
        notify.error(this.props.t('template_error'), error?.response ? backendTranslation(error?.response.data) : error?.message);
      });
  };

  componentDidMount() {
    this.getTemplateData();
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.location.search !== this.props.location.search) {
      //Query changed
      this.getTemplateData();
    }
  }

  render() {

    return (
      <div className="pt-4" style={{ backgroundColor: 'white' }}>
        {this.state.successId && <SubmitSuccessModal id={this.state.successId} onClose={()=> this.setState({ successId: undefined })}/>}
        <Row>
          <Col sm={{ size: 8, offset: 2 }}>
            <Link to={'/ui/templates'} className="d-flex align-items-center mb-3 submit-back" data-testid='submit-back-href'>
              <FaChevronLeft />
              <span className="ml-2">{this.props.t('submit_view.back_to_templates')}</span>
            </Link>
            <h1 className='submit-title'>{this.props.t('submit_view.submit_a_job')}</h1>

            { !this.state.templateData.isEmpty() &&
                this.state.templateData.getIn([ 'source', 'attributes', 'external_gui_form' ]) !== undefined &&
                  <ExternalForm
                    GUIUrl={this.state.templateData.getIn([ 'source', 'attributes', 'external_gui_form' ])}
                    onSubmit={this.submit}
                  />
            }

            { !this.state.templateData.isEmpty() && 
                this.state.templateData.getIn([ 'source', 'attributes', 'external_gui_form' ]) === undefined &&
              <>
                <div className="mt-2 d-flex align-middle">
                  <h6 className='m-0 my-auto pr-5 font-weight-normal'>
                    <span>{this.props.t('submit_view.submit_with_template') + "."}</span>
                  </h6>
                </div>
                <SubmitForm
                  templateSchema={this.state.templateData.get('schema').toJS()}
                  templateUiSchema={this.state.templateData.get('uiSchema').toJS()}
                  initialFormData={this.state.templateData.get('variables') && this.state.templateData.get('variables').toJS()}
                  message={this.state.templateData.get('messages') && this.state.templateData.get('messages').toJS()}
                  onSubmit={this.submit}
                  icon={this.state.templateData?.get('source')?.get('icon_url')}
                />
              </>
            }

            { this.state.loading &&
              <div className="text-center">
                <Spinner color="primary"/>
              </div>
            }

          </Col>
        </Row>
      </div>
    );
  }
}

Submit.propTypes = {
  t: PropTypes.func, //HOC
  sendRequest: PropTypes.func, //HOC
  location: PropTypes.object, //HOC
};

export default withRouter(Submit);