import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useLocation  } from 'react-router-dom';
import qs from 'qs';
import { Badge } from 'reactstrap';

import { saveToQuery } from '../jobTableUtils';

const FacetsFilter = ({ column_id, facets }) => {
  let location = useLocation();
  const facetKeys = facets ? Object.keys(facets) : [];

  const value = useMemo(()=> {
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });
    return query ? query[column_id] : [];
  }, [ location ]);

  const onValueChange = useCallback(event => {
    //convert undefined, string or array to array
    const currentValue = value
      ? (Array.isArray(value) ? [...value] : [value])
      : [];
    //add or remove clicked item
    const newValue = event.target.checked
      ? currentValue.concat(event.target.name)
      : currentValue.filter(n => n !== event.target.name);

    saveToQuery({
      [column_id]: newValue || null,
      page: 1,
    })
  }, [ value ]);
  return (
    <div>
      {
        facetKeys.map(key =>
          <label key={key} className="flex-vertical-center" onClick={event => {event.stopPropagation()}}>
            <input
              name={key}
              type="checkbox"
              onChange={onValueChange}
              checked={value?.includes(key) || false}
            />
            <span className="filter-text" title={key}>{key}</span>
            <Badge color="light" pill className="ml-2">{facets[key]}</Badge>
          </label>
        )
      }
    </div>
  )
}

FacetsFilter.propTypes = {
  column_id: PropTypes.string.isRequired,
  facets: PropTypes.object,
};

export default FacetsFilter;
