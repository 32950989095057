import { __env } from '../../ibis-ui/src/envloader';

export const theme = {
  serviceLogo: __env.THEME.serviceLogo,
  colors: {
    primary: {
      900: "#151357",
      800: "#2A278F",
      700: "#3734A3",
      600: "#4945C4",
      500: "#5E5ADB",
      400: "#807CEA",
      300: "#9E9BF5",
      200: "#B9B6FA",
      100: "#D0CFFC",
      50: "#DFDEFC",
      0: "#EDEDFC",
    },
    red: {
      600: "#BA1B2E",
      500: "#D1293D",
      300: "#F76F7F",
    },
    grey: {
      900: "#171C26",
      850: "#222834",
      800: "#333B4A",
      700: "#464F60",
      600: "#5A6376",
      500: "#687182",
      400: "#868FA0",
      300: "#A1A9B8",
      200: "#BCC2CE",
      100: "#D5DBE5",
      50: "#E9EDF5",
      0: "#F7F9FC",
    },
    lightGrey: "#F7F8FA",
    black: "#000000",
    white: "#FFFFFF",
    success: "#00A854",
    danger: "#D20724",
    warning: "#FFA216",
    error: "#FCEDED",
    ...__env.THEME.colors,
  },
  fontSize: {
    extraSmall: "10px",
    small: "12px",
    medium: "14px",
    large: "16px",
    mediumLarge: "18px",
    extraLarge: "20px",
    xxLarge: "24px",
    ...__env.THEME.fontSize,
  },
  fontWeight: {
    normal: "400",
    medium: "500",
    semiBold: "600",
    bold: "700",
    ...__env.THEME.fontWeight,
  },
};