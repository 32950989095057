/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import { CustomButton } from '../styles/styled-components/taskButton';

@ErrorBoundary((props) => {
  return `${props.t('show_file_button')}`;
})
export default class ShowFileButton extends Component {
  state = {
    modal: false
  };

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  };

  render() {
    const { t, withoutText, onlyIcon, onlyText, fileContent, fileName } = this.props;
    return (
      <>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="xl" >
          <ModalHeader toggle={this.toggle}><b>{fileName}</b></ModalHeader>
          <ModalBody style={{ overflow: "auto" }}>
            <div className="pre-scrollable">
              <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(fileContent, null, 2)}</pre>
            </div>
          </ModalBody>
        </Modal>

        <CustomButton
          size="sm"
          onClick={this.toggle}
          title={t('show_details_json')}
          color={withoutText || onlyIcon ? "white" : "secondary"}
          withoutText={withoutText}
          onlyIcon={onlyIcon}
          style={{ paddingBottom: "2px" }}
        >
          {!onlyText && "{}"}
          {!withoutText && !onlyIcon && <span>{t('show_details_json')}</span>}
        </CustomButton>
      </>
    );
  };
}

ShowFileButton.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileContent: PropTypes.object.isRequired, //TODO: use immutable prop type
  size: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  t: PropTypes.func, //HOC
  onlyIcon: PropTypes.bool,
  onlyText: PropTypes.bool,
  withoutText: PropTypes.bool
};
