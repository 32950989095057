import styled, { css } from 'styled-components';

import { theme } from '../../theme';

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  gap: 25px;
`;

export const DetailsUp = styled.div`
  background-color: rgba(247, 249, 252, 0.8);
  border-radius: 8px;
  width: 100%;
  padding: 16px 0 0 131px;
  display: flex;
  flex-direction: column;
  gap: 8px;  
`;

export const DetailsUpRow = styled.div`
  ${({ id }) => id && css`
    cursor: pointer;
  `};
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const DetailsUpRowText = styled.div`
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.semiBold};
`;

export const DetailsUpRowTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-width: 129px;
  min-height: 32px;
  color: ${theme.colors.grey[500]};
  font-size: ${theme.fontSize.medium};
`;

export const DetailsDown = styled.div`
  height: 238px;
  display: flex;
  flex-direction: column;
  padding: 32px 0 0 0;
  font-size: ${theme.fontSize.medium};
`;

export const DetailsDownRow = styled.div`
  min-height: 34px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 16px;
`;

export const DetailsDownRowTitle = styled.div`
  width: 282px;
  text-align: end;
  color: ${theme.colors.grey[500]};
  padding-right: 8px;
`;

export const History = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem 0 0 0;
`;

export const HistoryRow = styled.tr`
  display: flex;
  gap: 1rem;
`;

export const HistoryText = styled.td`
  font-size: 14px;
  color: #687182;
  width: 282px;
  text-align: end;
`;