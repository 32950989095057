/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import ConfirmationModal from '../components/confirmationModal';
import * as notify from '../utils/notify';
import { withSendRequest } from '../../main/hoc/withSendRequest';
import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('queue_state_switch')}`;
})
@withTranslation()
@connect(
  state => ({
    resources: state.resources,
  })
)
@withSendRequest
export default class QueueStateSwitch extends Component {
  state = {
    confirmationModal: false
  };
  interval;

  componentWillUnmount() {
    clearInterval(this.interval);
    this.interval = undefined;
  }

  renderConfirmationModal(queueName, operationTag, onClick) {
    const { t } = this.props;
    return this.state.confirmationModal && (
      <ConfirmationModal
        header={t('queue_state_switch')}
        body={t('confirm_queue_turn_' + operationTag, { queue: queueName })}
        onClick={onClick}
        onClose={() => this.setState({ confirmationModal: false })}
        btnId="confirm_state_switch"
      />
    );
  }

  render() {
    const { t, resources, queueName, queueState, fetchData, setLoadingFlag } = this.props;
    let btnColor, operationTag, translatedState, apiEndpoint;
    if (queueState === 'UP') {
      btnColor = 'danger';
      operationTag = 'off';
      translatedState = 'queue_state_up';
      apiEndpoint = "/down/";
    }
    if (queueState === 'DOWN') {
      btnColor = 'success';
      operationTag = 'on';
      translatedState = 'queue_state_down';
      apiEndpoint = "/up/";
    }
    return (
      <div>
        <span data-testid={queueName + '-state'}>({t(translatedState)})</span>
        <div>
          <Button
            color={btnColor}
            size='sm'
            className='queue-state-switch-button py-0'
            onClick={() => this.setState({ confirmationModal: true })}
          >{t('turn_' + operationTag + '_queue')}</Button>
        </div>
        {this.renderConfirmationModal(queueName, operationTag, () => {
          this.props.sendRequest("post", "/resources/queues/" + queueName + apiEndpoint)
            .then(() => {
              setLoadingFlag(true, () => {
                this.interval = setInterval(() => {
                  fetchData();
                  if (queueState !== resources.getIn([ queueName, 'state' ])) {
                    clearInterval(this.interval);
                    this.interval = undefined;
                    setLoadingFlag(false);
                    setTimeout(fetchData, 1000);
                  }
                }, 2500);
                setTimeout(() => {
                  if (this.interval) {
                    clearInterval(this.interval);
                    this.interval = undefined;
                    setLoadingFlag(false);
                    notify.error(t('timeout'), t('queue_state_change_fail'));
                  }
                }, 60000);
              });
            });
        })}
      </div>
    );
  }
}

QueueStateSwitch.propTypes = {
  queueName: PropTypes.string.isRequired,
  queueState: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  setLoadingFlag: PropTypes.func.isRequired,
  sendRequest: PropTypes.func, //HOC
  resources: PropTypes.object, //HOC
  t: PropTypes.func, //HOC
};
