import React, { useCallback } from 'react';
import { useLocation  } from 'react-router-dom';
import qs from 'qs';
import PropTypes from 'prop-types';

import Pagination from '../jobs/pagination';
import { saveToQuery } from './jobTableUtils';

const TableFooter = ({ rows }) => {
  let location = useLocation();

  const changePage = useCallback(page => {
    saveToQuery({ page });
  }, []);

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const pages = rows && query.page_size && Math.ceil(rows / query.page_size);

  return (
    <div className="job-table-footer">
      {
        query.page && !!pages &&
          <Pagination
            page={parseInt(query.page)}
            pages={pages}
            onPageChange={changePage}
          />
      }
    </div>
  );
};

TableFooter.propTypes = {
  rows: PropTypes.number,
};

export default TableFooter;