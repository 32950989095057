import i18n from '../../../main/utils/i18n';
import { AiOutlineArrowUp, AiOutlineArrowDown, AiOutlineLine, AiOutlinePlus } from 'react-icons/ai';

export const customFieldTemplate = props => {
  const { id, classNames, help, errors, children, displayLabel, label, uiSchema } = props;
  if (uiSchema["ui:widget"] === "hidden") return <></>;
  if (id !== 'root') {
    if (!displayLabel) return (<>{children}</>);
    return ( 
      <div className={classNames + ' submit-input'}>
        {label}
        {children}
        {errors}
        {help}
      </div>
    );
  }
  return (<>{children}</>);
};

export const customArrayFieldTemplate = props => (
  <div className="submit-form-array">
    <p>{props.title}{props.required && <span className="blue-star"> *</span>}</p>
    {props.items &&
      props.items.map((element) => (
        <div key={element.key} className="array-children">
          <div className="input-container">{element.children}</div>
          <button
            onClick={element.onReorderClick(element.index, element.index - 1)}
            disabled={!element.hasMoveUp}
          >
            <AiOutlineArrowUp />
          </button>
          <button
            onClick={element.onReorderClick(element.index, element.index + 1)}
            disabled={!element.hasMoveDown}
          >
            <AiOutlineArrowDown />
          </button>
          <button onClick={element.onDropIndexClick(element.index)}>
            <AiOutlineLine />
          </button>
        </div>
      ))}

    {props.canAdd && (
      <div className="row">
        <p className="col-xs-3 col-xs-offset-9 array-item-add text-right ml-2">
          <button onClick={props.onAddClick} type="button" className="add-value">
            <AiOutlinePlus />
            <span>{i18n.t('submit_form.add_value')}</span>
          </button>
        </p>
      </div>
    )}
  </div>
);