/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/


import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

const InputIframeModal = (props) => {
  const [ value, setValue ] = useState(undefined);

  window.onmessage = function (e) {
    if (e.origin === new URL(props.providerData.url).origin){
      if (props.providerData.ui_prefix && props.providerData.actual_prefix)
        setValue(e.data.replace(props.providerData.ui_prefix, props.providerData.actual_prefix));
      else
        setValue(e.data);
    }
  };

  const [ urlWithoutQuery, query ] = props.providerData.url.includes('?') ? 
    props.providerData.url.split('?') :
    [ props.providerData.url, '' ];

  const newQuery = new URLSearchParams(query);
  newQuery.set('lng', props.i18n.language);
  const finalUrl = `${urlWithoutQuery}?${newQuery.toString()}`;

  return (
    <Modal isOpen={true} size='lg' className="iframe-modal" >
      <ModalHeader toggle={props.onClose}>{props.title}</ModalHeader>
      <ModalBody>
        <div className="iframe-container">
          <iframe title={props.title} src={finalUrl} ></iframe>
        </div>
      </ModalBody>
      <ModalFooter className='iframe-modal-footer'>
        <p>{props.t('fileSelectorWidget.chosenFile')} {value}</p>
        <div>
          <Button onClick={props.onClose} className="mr-3">
            {props.t('close')}
          </Button>
          <Button onClick={() => props.submitValue(value)}>
            {props.t('fileSelectorWidget.submit')}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

InputIframeModal.propTypes = {
  t: PropTypes.func, //HOC
  i18n: PropTypes.object, //HOC
  onClose: PropTypes.func,
  submitValue: PropTypes.func,
  providerData: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default withTranslation()(InputIframeModal);