import styled from 'styled-components';
import { Row } from 'reactstrap';

import { Input } from '../input/input';
import { theme } from '../../styles';
import { Button } from '../button/button';

export const StyledRow = styled(Row)`
  display: flex;
  align-items: center;
  background-color: ${theme.colors.grey[50]};
  color: ${theme.colors.grey[700]};
  font-size: ${theme.fontSize.small};
  font-weight: 500;
  border-radius: 4px;
`;

export const StyledFile = styled(Input)`
  all: unset;
  flex: 1;
  padding: 2px;
  border-radius: 4px;
`;

export const StyledEditButton = styled(Button)`
  :hover {
    background-color: transparent;
  }
  margin-left: -28px;
`;
