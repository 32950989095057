/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

import { ErrorBoundary } from '../../../main/hoc/errorboundary';
import { CustomButton, CustomModal } from '../../styles/styled-components/submitForm';

@ErrorBoundary(props => props.t('submit_view.submit_success_modal'), true)
@withTranslation()
class SubmitSuccessModal extends Component {
  render() {
    return (
      <CustomModal isOpen={true} onClose={this.props.onClose} centered={true} style={{ maxWidth: "480px" }}>
        <ModalHeader>
          {this.props.t('submit_view.job_submit_success_modal_header')}
        </ModalHeader>
        <ModalBody>
          <Trans i18nKey="submit_view.job_submit_success_modal_body" values={{ id: this.props.id }}>
            <b></b>
          </Trans>
        </ModalBody>
        <ModalFooter>
          <CustomButton tag={Link} to={`/ui/jobs/${this.props.id}`}>
            {this.props.t('submit_view.see_job_details')}
          </CustomButton>
          <CustomButton onClick={this.props.onClose} primary className="ml-3">
            {this.props.t('submit_view.submit_another')}
          </CustomButton>
        </ModalFooter>
      </CustomModal>
    );
  }
}

SubmitSuccessModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  t: PropTypes.func, //HOC
  location: PropTypes.object,
};

export default withRouter(SubmitSuccessModal);
