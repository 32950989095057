import { ADD_INACTIVE_ACCOUNTS, FINISH_INACTIVE_ACCOUNTS_LOADING } from '../actions/userAccounts';

const initialState = {
  inactiveAccounts: [],
  isLoading: true
};

export const inactiveAccountsReducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_INACTIVE_ACCOUNTS:
    if (!state.inactiveAccounts.includes(action.payload)) {
      return {
        inactiveAccounts: [ ...state.inactiveAccounts, action.payload ]
      };
    }
    return { inactiveAccounts: [ ...state.inactiveAccounts ], isLoading: false };
  case FINISH_INACTIVE_ACCOUNTS_LOADING:
    return {
      inactiveAccounts: [ ...state.inactiveAccounts ],
      isLoading: false
    };
  default: 
    return state;
  }
};