import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link, useLocation  } from 'react-router-dom';
import qs from 'qs';
import { uniqueId, cloneDeep } from 'lodash';

const pushActiveTabToURL = (nav, location) => {
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  return qs.stringify({ ...params, "activeTab": nav });
};

export const uiConditional = (disabled, isScenario , uiSchema, qsTab = "general") => {
  let newUiConditionalSchema = cloneDeep(uiSchema);

  if (newUiConditionalSchema.name !== undefined) {
    disabled && !isScenario ? newUiConditionalSchema.name["ui:widget"] = "hidden" : newUiConditionalSchema.name["ui:widget"] = undefined;
  }

  for (const [ key, value ] of Object.entries(newUiConditionalSchema)) {
    if (value.nav !== (qsTab) && value.nav ) {
      newUiConditionalSchema[key]["ui:widget"] = "hidden";
    }
    else if (newUiConditionalSchema[key]["ui:widget"] != null) {
      if (key === "name" && disabled) {
        continue;
      }
      newUiConditionalSchema[key]["ui:widget"] = uiSchema[key]["ui:widget"];
    }
    if (uiSchema[key]["ui:widget"] === "checkboxes") {
      newUiConditionalSchema[key]["classNames"] = "gridCheckboxes";
    }
  }

  return newUiConditionalSchema;
};

export const CustomNavs = ({ uiSchema, isNested, setNestedTabState, nestedTabState, className }) => {
  let location = useLocation();

  const nestedTabHandler = (e, tab) => {
    e.preventDefault();
    setNestedTabState(tab);
  };

  return (
    <Nav tabs className={className}>
      {uiSchema?.navConf?.navs?.map(element => {
        return <NavItem key={uniqueId(element.name)}>
          {isNested ? 
            <button 
              className={classnames({ active: element.nav === nestedTabState }, "nav-link")}
              onClick={(e) => nestedTabHandler(e, element.nav)}
            >
              &nbsp;
              {element.name}  
            </button>
            :
            <NavLink
              className={classnames({ active: element.nav === qs.parse(location.search, { ignoreQueryPrefix: true }).activeTab })}
              tag={Link}
              to={{ search: pushActiveTabToURL(element.nav, location) }}
            >
              &nbsp;
              {element.name}
            </NavLink>
          }
        </NavItem>;
      })}
    </Nav>
  );
};

CustomNavs.propTypes = {
  className: PropTypes.string,
  uiSchema: PropTypes.object,
  nestedTabState: PropTypes.string,
  setNestedTabState: PropTypes.func,
  isNested: PropTypes.bool
};