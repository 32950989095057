import React from 'react';

import JobTable from './jobTable';

const Jobs = () => {

  return (
    <JobTable/>
  )
};

export default Jobs;
