import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Row, Col } from 'reactstrap';
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useLocation  } from 'react-router-dom';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import axios from '@axios';
import { getColumns } from './jobTableUtils';
import TableFooter from './tableFooter';
import { saveToQuery } from './jobTableUtils';
import JobTableFilters from './jobTableFilters';
import JobGroupActions from "./jobGroupActions";

const JobTable = () => {
  const [ data, setData ] = useState([]);
  const [ dataCount, setDataCount ] = useState(undefined);
  const [ facets, setFacets ] = useState(undefined);
  const [ loading, setLoading ] = useState(false);
  const [ rowSelection, setRowSelection ] = useState({});
  let location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    //supplement required qs params
    saveToQuery({});
  }, [])

  const fetchData = useCallback(() => {
    setRowSelection({});
    setLoading(true);
    const searchProps = location.search.replace('?','');
    if (searchProps)
      axios.get("/jobs/search/?" + searchProps + '&facet=queue&facet=major_state&facet=template_name&facet=grant')
        .then(response => {
          setData(response.data.results);
          setDataCount(response.data.count);
          setFacets(response.data.facets);
          setLoading(false);
        })
  }, [ location ]);

  useEffect(fetchData, [ fetchData ]);

    const table = useReactTable({
      data,
      columns: getColumns(),
      state: {
        rowSelection,
      },
      enableRowSelection: true,
      onRowSelectionChange: setRowSelection,
      getCoreRowModel: getCoreRowModel(),
    });

    return (
      <Row>
        <Col xs={2}>
          <JobTableFilters
            columns={getColumns()}
            facets={facets}
          />
        </Col>
        <Col xs={10}>
          <div className="p-2">
            <h1 className="mt-4">{t('jobTable.main_header')}</h1>
            <div className="mb-3">{t('jobTable.results_count', {count: dataCount})}</div>

            {
              !_.isEmpty(rowSelection) &&
                <JobGroupActions selection={table.getSelectedRowModel().flatRows} />
            }

            <Table size="sm" hover>
              <thead>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id}>
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </th>
                  ))}
                </tr>
              ))}
              </thead>
              <tbody>
              {table.getRowModel().rows.map(row => (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            </Table>
            <TableFooter rows={dataCount}/>
            {
              loading && <Spinner/>
            }
          </div>
        </Col>
      </Row>
    )
};

export default JobTable;
