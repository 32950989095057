/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { fromJS } from 'immutable';

import { LOAD_TEMPLATE, TEMPLATES_LOADING, TEMPLATES_LOADED } from '../actions/templates';

const initialState = fromJS({
  isLoading: true,
  data: {},
});

export const templateReducer  = (state = initialState, action) => {
  switch (action.type) {

  case TEMPLATES_LOADING:
    return state.set("isLoading", true);
  case LOAD_TEMPLATE:
    return state.update('data', data => data.set(action.payload.id, fromJS(action.payload.template)));
  case TEMPLATES_LOADED:
    return state.set("isLoading", false);
  default:
    return state;
  }
};
