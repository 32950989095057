import { createColumnHelper } from '@tanstack/react-table';
import qs from 'qs';
import { Link } from 'react-router-dom';

import { history } from '../../../main/stores/configureStore';
import { titledHeader } from '../../utils/table';
import TaskState from '../../components/taskState';
import { formatDate } from '../../utils/tasksgrid';
import JobTableRowActions from './jobTableRowActions';
import JobTableColumnHeader from './jobTableColumnHeader';
import IndeterminateCheckbox from './indeterminateCheckbox';

const columnHelper = createColumnHelper();

export const TEXT_FILTER = "TEXT_FILTER";
export const FACETS_FILTER = "FACETS_FILTER";

export const getColumns = () => ([
  columnHelper.display({
    header: ({ table }) => (
      <IndeterminateCheckbox
        {...{
          checked: table.getIsAllPageRowsSelected(),
          indeterminate: table.getIsSomePageRowsSelected(),
          onChange: table.getToggleAllPageRowsSelectedHandler(),
        }}
      />
    ),
    id: 'select',
    cell: ({ row }) => (
      <div className="px-1">
        <IndeterminateCheckbox
          {...{
            checked: row.getIsSelected(),
            disabled: !row.getCanSelect(),
            indeterminate: row.getIsSomeSelected(),
            onChange: row.getToggleSelectedHandler(),
          }}
        />
      </div>
    ),
  }),
  columnHelper.accessor('note', {
    header: <JobTableColumnHeader column_id="note" isSortable />,
    cell: info => (
      <Link to={`/ui/jobs/${info?.row?.original?.id || ""}/`}>
        {titledHeader(info.getValue())}
      </Link>
    ),
    meta: { filter: TEXT_FILTER }
  }),
  columnHelper.accessor('id', {
    header: <JobTableColumnHeader column_id="id" isSortable />,
    cell: info => (
      <Link to={`/ui/jobs/${info.getValue()}/`}>
        {titledHeader(info.getValue())}
      </Link>
    ),
    meta: { filter: TEXT_FILTER }
  }),
  columnHelper.accessor('major_state', {
    header: <JobTableColumnHeader column_id="major_state" isSortable />,
    cell: info => <TaskState state={info.getValue()} />,
    meta: { filter: FACETS_FILTER }
  }),
  columnHelper.accessor('template_name', {
    header: <JobTableColumnHeader column_id="template_name" isSortable />,
    cell: info => titledHeader(info.getValue()),
    meta: { filter: FACETS_FILTER }
  }),
  columnHelper.accessor('submit_time', {
    header: <JobTableColumnHeader column_id="submit_time" isSortable />,
    cell: info => formatDate(info.getValue(), false, 'submit-time-' + info.row.id, "DD.MM.Y HH:mm")
  }),
  columnHelper.accessor('updated_time', {
    header: <JobTableColumnHeader column_id="updated_time" isSortable />,
    cell: info => formatDate(info.getValue(), true, 'updated-time-' + info.row.id)
  }),
  columnHelper.display({
    header: <JobTableColumnHeader column_id="actions"/>,
    id: 'actions',
    cell: props => <JobTableRowActions row={props.row} />,
  }),
]);

//const defaultFiltered = [];
const defaultSorted = "-submit_time";
const defaultPage = 1;
const defaultPageSize = 10;

export const saveToQuery = (obj, keepFilters = true) => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const prevQuery = keepFilters ? query : {};
  const newQuery = {
    page: query.page ? query.page : defaultPage,
    page_size: query.page_size ? query.page_size : defaultPageSize,
    ordering: query.ordering ? query.ordering : defaultSorted,
    ...prevQuery,
    ...obj
  };
  const newQs = '?' + qs.stringify(newQuery, { indices: false, skipNulls: true });
  if (window.location.search !== newQs) {
    history.push(window.location.pathname + newQs);
  }
};