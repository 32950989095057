/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { FaEraser } from 'react-icons/fa';

import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('clean_button')}`;
})
export default class CleanButton extends Component {
  handleClick = () => {
    this.props.callback();
  };

  render() {
    return (
      <span className="float-right remove-filter">
        <Button
          outline
          color="dark"
          size="sm"
          className="border-0 px-1"
          onClick={this.handleClick}>
          <FaEraser/>
        </Button>
      </span>
    );
  }
}

CleanButton.propTypes = {
  callback: PropTypes.func.isRequired
};
