/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import moment from 'moment';
import { List } from 'immutable';

export const dataLength = 50;
export const colorPalette = [ '#f5b454', '#f65495', '#548ff6', '#89f654', '#54f6c1', '#b854f6' ];
export const series = [ "PENDING", "EXECUTING", "FAILED", "CANCELED", "FINISHED" ];

const formatDay = (momentObj) => {
  return momentObj.format('L');
};

const formatHour = (momentObj) => {
  return momentObj.format('HH:mm DD.MM');
};

const formatMinute = (momentObj) => {
  return momentObj.format('HH:mm');
};

const formatMomentObject = (unit, momentObj) => {
  if (unit === 'day') return formatDay(momentObj);
  else if (unit === 'hour') return formatHour(momentObj);
  else if (unit === 'minute') return formatMinute(momentObj);
  else return momentObj;
};

const fillEmptyData = (data, unit, quantity) => {
  let now;
  if (unit === 'day')
    now = moment().startOf(unit).hours(2);
  else
    now = moment().startOf(unit);
  let lastElement, firstElement, nowMutable;
  //IF THERE IS NO DATA:
  if (data.x.length === 0) {
    data['series'] = series;
    for (let i = 0; i < quantity; i++) {
      nowMutable = now.clone();
      data.x.unshift(nowMutable.subtract(i, unit).toISOString());
      data.data.unshift([ 0, 0, 0, 0, 0 ]);
    }
  }
  //IF THERE IS SOME DATA BUT NOT THE LENGTH OF QUANTITY
  else {
    lastElement = moment(data.x[data.x.length - 1]);
    firstElement = moment(data.x[0]);
    while (lastElement.isBefore(now)) {
      data.x.push(lastElement.add(1, unit).toISOString());
      lastElement = moment(data.x[data.x.length - 1]);
      data.data.push([ 0, 0, 0, 0, 0 ]);
    }
    const firstFromNow = now.clone().subtract(quantity - 1, unit);
    while (firstElement.isAfter(firstFromNow)) {
      data.x.unshift(firstElement.subtract(1, unit).toISOString());
      firstElement = moment(data.x[0]);
      data.data.unshift([ 0, 0, 0, 0, 0 ]);
    }
  }
};

export const queuesTaskCountParseData = (data) => {
  const parsedData = [];
  Object.entries(data).forEach(entry => parsedData.push({ name: entry[0], value: entry[1] }) );
  return List(parsedData);
};

export const intervalTimeParseData = (data, unit) => {
  const filledData = data, chartData = [];
  if (unit)
    fillEmptyData(filledData, unit, dataLength);
  filledData.x.forEach((date, dateIndex) => {
    const singleTickObject = !unit ? {} : { name: formatMomentObject(unit, moment(date)) };
    filledData.series.forEach((series, seriesIndex) => singleTickObject[series] = filledData.data[dateIndex][seriesIndex]);
    chartData.push(singleTickObject);
  });
  return List(chartData);
};

export const getDataForModeChart = (mode, props) => {
  if (mode === 'day') {
    return props.dayData;
  }
  else if (mode === 'hour') {
    return props.hourData;
  }
  else if (mode === 'minute') {
    return props.minuteData;
  }
  else return { data: List(), keys: List() };
};