/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Badge, UncontrolledTooltip } from 'reactstrap';
import { GoAlert } from 'react-icons/go';
import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return "MiniAlert";
})
export default class MiniAlert extends Component {
  render() {
    // Random id allowing multiple usage on one site
    const tooltipId = "tooltip-" + Math.floor(Math.random() * 1000001).toString(10);
    return (
      <div className="mini-alert">
        <Badge color="warning" id={tooltipId}>
          <GoAlert className="mr-1"/>{this.props.content}
        </Badge>
        <UncontrolledTooltip placement="bottom" target={tooltipId}>
          {this.props.tooltip}
        </UncontrolledTooltip>
      </div>
    );
  }
};

MiniAlert.propTypes = {
  content: PropTypes.string,
  tooltip: PropTypes.string,
};