/* eslint-disable */
import * as React from "react"

const SvgComponent = (props) => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x={0}
    y={0}
    viewBox="0 0 110 135"
    style={{
      enableBackground: "new 0 0 110 135",
    }}
    xmlSpace="preserve"
    {...props}
  >
    <style>
      {
        ".st2{fill:#fff}.st8,.st9{fill:#151357}.st9{opacity:.1;enable-background:new}"
      }
    </style>
    <linearGradient
      id="SVGID_1_"
      gradientUnits="userSpaceOnUse"
      x1={70.25}
      y1={626}
      x2={70.25}
      y2={566}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#bebebe",
        }}
      />
      <stop
        offset={0.2}
        style={{
          stopColor: "#c9c9c9",
        }}
      />
      <stop
        offset={0.68}
        style={{
          stopColor: "#dedede",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#e6e6e6",
        }}
      />
    </linearGradient>
    <path
      d="M101.7 0H38.9c-3.1 0-5.7 2.5-5.7 5.6v48.8c0 3.1 2.5 5.6 5.7 5.6h62.8c3.1 0 5.7-2.5 5.7-5.6V5.6c0-3.1-2.6-5.6-5.7-5.6z"
      style={{
        fill: "url(#SVGID_1_)",
      }}
    />
    <linearGradient
      id="SVGID_00000000209338308775022650000017177713736215427241_"
      gradientUnits="userSpaceOnUse"
      x1={33.15}
      y1={573.9}
      x2={107.35}
      y2={573.9}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#2a278f",
        }}
      />
      <stop
        offset={0}
        style={{
          stopColor: "#151257",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#4945c4",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000000209338308775022650000017177713736215427241_)",
      }}
      d="M107.4 15.8V5.7c0-3.1-2.6-5.7-5.8-5.7H38.9c-3.1 0-5.7 2.5-5.7 5.6v10.2h74.2z"
    />
    <path
      className="st2"
      d="M52.2 11c1.7 0 3.2-1.4 3.2-3.2 0-1.7-1.4-3.2-3.2-3.2C50.5 4.6 49 6 49 7.8s1.4 3.2 3.2 3.2z"
    />
    <path
      d="M42.7 11c1.7 0 3.2-1.4 3.2-3.2 0-1.7-1.4-3.2-3.2-3.2-1.7 0-3.2 1.4-3.2 3.2S41 11 42.7 11z"
      style={{
        opacity: 0.7,
        fill: "#fff",
        enableBackground: "new",
      }}
    />
    <linearGradient
      id="SVGID_00000045611966148580114250000016558399382609233338_"
      gradientUnits="userSpaceOnUse"
      x1={60.808}
      y1={610.212}
      x2={81.797}
      y2={578.786}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#807cea",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#6d5ee7",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000045611966148580114250000016558399382609233338_)",
      }}
      d="M96.4 23.7H44.2c-2.5 0-4.6 2.2-4.6 4.9v2.8c0 2.7 2.1 4.9 4.6 4.9h52.3c2.5 0 4.6-2.2 4.6-4.9v-2.8c0-2.7-2.2-4.9-4.7-4.9z"
    />
    <linearGradient
      id="SVGID_00000009577045476456730970000001578630481489473159_"
      gradientUnits="userSpaceOnUse"
      x1={62.43}
      y1={624.367}
      x2={79.752}
      y2={594.726}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#807cea",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#6d5ee7",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000009577045476456730970000001578630481489473159_)",
      }}
      d="M96.4 39.4H44.2c-2.5 0-4.6 1.9-4.6 4.3v2.4c0 2.4 2.1 4.3 4.6 4.3h52.3c2.5 0 4.6-1.9 4.6-4.3v-2.4c0-2.3-2.2-4.3-4.7-4.3z"
    />
    <linearGradient
      id="SVGID_00000148622131293392054180000008867599259664344452_"
      gradientUnits="userSpaceOnUse"
      x1={44.2}
      y1={659.1}
      x2={44.2}
      y2={596}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#c8c8c8",
        }}
      />
      <stop
        offset={0.2}
        style={{
          stopColor: "#d3d3d3",
        }}
      />
      <stop
        offset={0.68}
        style={{
          stopColor: "#e8e8e8",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#f0f0f0",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000148622131293392054180000008867599259664344452_)",
      }}
      d="M76.2 30h-64c-3.2 0-5.8 2.6-5.8 5.9v51.3c0 3.2 2.6 5.9 5.8 5.9h64.1c3.2 0 5.8-2.6 5.8-5.9V35.9c0-3.3-2.6-5.9-5.9-5.9z"
    />
    <path
      d="M82.1 45.8V35.5c0-3-2.6-5.5-5.7-5.5H12.3c-3.2 0-5.9 2.5-5.9 5.6v10.1h75.7v.1z"
      style={{
        fill: "#5e5adb",
      }}
    />
    <path
      className="st2"
      d="M26.9 41c1.7 0 3.2-1.4 3.2-3.2 0-1.7-1.4-3.2-3.2-3.2-1.7 0-3.2 1.4-3.2 3.2.1 1.8 1.5 3.2 3.2 3.2z"
    />
    <path
      className="st8"
      d="M15.9 41c1.7 0 3.2-1.4 3.2-3.2 0-1.7-1.4-3.2-3.2-3.2-1.7 0-3.2 1.4-3.2 3.2s1.4 3.2 3.2 3.2zM92.6 85.3l-4.7-14.1c-.7-2.2 1.3-4.2 3.5-3.5l14.1 4.7c2.6.9 3.4 4.2 1.4 6.1l-8.2 8.2c-2 1.9-5.3 1.1-6.1-1.4z"
    />
    <path
      className="st8"
      d="M108.9 89.8c-.3 0-.5-.1-.7-.3L96.6 77.8c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l11.6 11.7c.4.4.4 1 0 1.4-.2.2-.4.3-.7.3z"
    />
    <path
      className="st9"
      d="M28.1 51.9H8.2c-3.5 0-6.4 2.8-6.4 6.4v17c0 3.5 2.8 6.4 6.4 6.4h19.9c3.5 0 6.4-2.8 6.4-6.4v-17c-.1-3.5-2.9-6.4-6.4-6.4zM45.9 101.9h19.8c3.5 0 6.4-2.8 6.4-6.4v-17c0-3.5-2.8-6.4-6.4-6.4H45.9c-3.5 0-6.4 2.8-6.4 6.4v17c.1 3.6 2.9 6.4 6.4 6.4z"
    />
    <linearGradient
      id="SVGID_00000005247427552734219290000011430596949926737598_"
      gradientUnits="userSpaceOnUse"
      x1={1.523}
      y1={639.182}
      x2={33.115}
      y2={628.968}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#807cea",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#6d5ee7",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000005247427552734219290000011430596949926737598_)",
      }}
      d="M25.5 53.6H6.3C2.9 53.6.1 56.5.1 60v17.1c0 3.5 2.8 6.4 6.2 6.4h19.2c3.4 0 6.2-2.9 6.2-6.4v-17c0-3.6-2.8-6.5-6.2-6.5z"
    />
    <linearGradient
      id="SVGID_00000075120295313979754730000006633587645580840320_"
      gradientUnits="userSpaceOnUse"
      x1={53.65}
      y1={670.121}
      x2={53.65}
      y2={640.147}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#c8c8c8",
        }}
      />
      <stop
        offset={0.2}
        style={{
          stopColor: "#d3d3d3",
        }}
      />
      <stop
        offset={0.68}
        style={{
          stopColor: "#e8e8e8",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#f0f0f0",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000075120295313979754730000006633587645580840320_)",
      }}
      d="M63.3 74.1H44.1c-3.4 0-6.2 2.9-6.2 6.4v17.1c0 3.5 2.8 6.4 6.2 6.4h19.2c3.4 0 6.2-2.9 6.2-6.4V80.5c0-3.5-2.7-6.4-6.2-6.4z"
    />
    <linearGradient
      id="SVGID_00000078757498528946178120000017843188855704045444_"
      gradientUnits="userSpaceOnUse"
      x1={53.65}
      y1={633.836}
      x2={53.65}
      y2={619.636}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#c8c8c8",
        }}
      />
      <stop
        offset={0.2}
        style={{
          stopColor: "#d3d3d3",
        }}
      />
      <stop
        offset={0.68}
        style={{
          stopColor: "#e8e8e8",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#f0f0f0",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000078757498528946178120000017843188855704045444_)",
      }}
      d="M64.2 53.6H43.1c-2.9 0-5.2 2.2-5.2 5v4.2c0 2.8 2.3 5 5.2 5h21.2c2.9 0 5.2-2.2 5.2-5v-4.2c0-2.7-2.3-5-5.3-5z"
    />
    <path
      className="st2"
      d="M63 61.5H44.1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H63c.3 0 .5.2.5.5s-.2.5-.5.5zM49 93.6c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l9.5-9.5c.2-.2.5-.2.7 0s.2.5 0 .7l-9.5 9.5s-.2.1-.3.1z"
    />
    <path
      className="st2"
      d="M58.5 93.6c-.1 0-.3 0-.4-.1L48.6 84c-.2-.2-.2-.5 0-.7s.5-.2.7 0l9.5 9.5c.2.2.2.5 0 .7-.1 0-.2.1-.3.1z"
    />
    <linearGradient
      id="SVGID_00000062158608782401205850000016244782324493412245_"
      gradientUnits="userSpaceOnUse"
      x1={13.5}
      y1={649.612}
      x2={13.5}
      y2={629.104}
      gradientTransform="translate(0 -566)"
    >
      <stop
        offset={0}
        style={{
          stopColor: "#c8c8c8",
        }}
      />
      <stop
        offset={0.2}
        style={{
          stopColor: "#d3d3d3",
        }}
      />
      <stop
        offset={0.68}
        style={{
          stopColor: "#e8e8e8",
        }}
      />
      <stop
        offset={1}
        style={{
          stopColor: "#f0f0f0",
        }}
      />
    </linearGradient>
    <path
      style={{
        fill: "url(#SVGID_00000062158608782401205850000016244782324493412245_)",
      }}
      d="M.1 72.5 7 64.2c1.2-1.5 3.4-1.4 4.6.1L27 83.7H6.1C2.8 83.7.2 81 .2 77.6l-.1-5.1z"
    />
    <path
      className="st2"
      d="M23 64.7c1.3 0 2.4-1.1 2.4-2.4s-1.1-2.4-2.4-2.4-2.4 1.1-2.4 2.4 1.1 2.4 2.4 2.4z"
    />
    <path
      d="M18.1 131.5c0-1.9 1.6-3.5 3.5-3.5h70c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5h-70c-1.9 0-3.5-1.6-3.5-3.5z"
      style={{
        opacity: 0.08,
        fill: "#171c26",
        enableBackground: "new",
      }}
    />
  </svg>
)

export default SvgComponent
