/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const AutoRefresh = ({ refresh, time, setTime }) => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime( time - 1 );
    }, 1000);

    return () => clearInterval(intervalId);
  }, [ time, setTime ]);

  useEffect(() => {
    if (time <= 0) {
      refresh();
      setTime(30);
    }
  }, [ time, refresh, setTime ]);

  return (
    <div className="progress-circle">
      <svg>
        <circle className="circle-background" r="8" cx="16" cy="16" />
        <circle
          className="circle-progress"
          r="8"
          cx="16"
          cy="16"
          style={{ strokeDashoffset: 1.66 * time }}
        />
      </svg>
      <div className="time">
        <span className="time-value">{time.toString()}</span>
      </div>
    </div>
  );
};

AutoRefresh.propTypes = {
  refresh: PropTypes.func.isRequired,
  time: PropTypes.number.isRequired,
  setTime: PropTypes.func.isRequired,
};

export default AutoRefresh;