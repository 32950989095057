import { Folder, Settings } from '../../assets/img/sidebar/icons';
import { __env } from '../../envloader';
import { StyledIcon } from '../../qcg-ui/styles/styled-components/GlobalSidebar';
export const navItems = [
  {
    label: 'nav_items.folder',
    path: 'Folder',
    icon: (props) => <StyledIcon as={Folder} {...props} />,
    url: __env.SUBMIT_FORM_FILE_SELECTOR_PROVIDERS.find(ele => ele.id === 'ibis-cluster-home')?.url,
    target: "_blank"
  },
  {
    label: 'nav_items.settings',
    path: 'Settings',
    icon: (props) => <StyledIcon as={Settings} {...props} />
  },
];