/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../main/hoc/errorboundary';

@ErrorBoundary((props) => {
  return `${props.t('confirmation_modal')}`;
}, true)
@withTranslation()
export default class ConfirmationModal extends Component {
  render() {
    const { t, header, body, onClick, onClose, btnId, size } = this.props;
    return (
      <Modal isOpen={true} onClose={onClose} size={size}>
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>
          {body}
        </ModalBody>
        <ModalFooter>
          <Button
            id={btnId}
            className="float-left"
            color="secondary"
            onClick={() => {
              onClick();
              onClose();
            }}>
            {t('yes')}
          </Button>
          <Button color="danger" onClick={onClose} >
            {t('no')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

ConfirmationModal.propTypes = {
  header: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  btnId: PropTypes.string,
  size: PropTypes.string,
  t: PropTypes.func, //HOC
};