import _ from 'lodash';
import i18n from 'i18next';

const availableLanguages = [ 'pl', 'en' ];
const defaultLang = 'en';

const backendTranslation = (field, ifEmptyValue = '') => {
  const lang = availableLanguages.includes(i18n.language.substring(0,2)) ? i18n.language.substring(0,2) : defaultLang;
  return (field && !_.isEmpty(field))
    ? (field.hasOwnProperty(lang)? field[lang] : field)
    : ifEmptyValue;
};

export default backendTranslation;