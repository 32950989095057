/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { Map } from 'immutable';
import _ from 'lodash';

const namespace = '@QCG_RESOURCES/';

export const LOAD_RESOURCES_DATA = namespace + "LOAD_RESOURCES_DATA";
export const LOAD_PENDING_TASKS_COUNT = namespace + "LOAD_PENDING_TASKS_COUNT";
export const LOAD_MAX_ETA = namespace + "LOAD_MAX_ETA";
export const LOAD_EXECUTING_TASKS_COUNT = namespace + "LOAD_EXECUTING_TASKS_COUNT";
export const LOAD_TOTAL_TASKS_COUNT = namespace + "LOAD_TOTAL_TASKS_COUNT";

export const loadResourcesData = ({ sendRequest }) => dispatch => {
  sendRequest("get", "resources/")
    .then(response => {
      dispatch({
        type: LOAD_RESOURCES_DATA,
        payload: response.data
      });

      if (response.data && response.data.queues && response.data.queues.list) {
        Map(response.data.queues.list).forEach((queue_item, queue_name) => {
          sendRequest("get", "jobs/search/?state=PENDING&queue=" + queue_name + "&ordering=-eta&page_size=1")
            .then(response => {
              //Download pending tasks count for each queue
              const pending = _.get(response, 'data.count', null);
              if (pending)
                dispatch({
                  type: LOAD_PENDING_TASKS_COUNT,
                  payload: {
                    name: queue_name,
                    count: pending
                  }
                });

              //Download max eta for each queue
              const max = _.get(response, 'data.results[0].eta', null);
              if (max)
                dispatch({
                  type: LOAD_MAX_ETA,
                  payload: {
                    name: queue_name,
                    eta: max
                  }
                });
            });
          sendRequest("get", "jobs/search/?state=EXECUTING&queue=" + queue_name)
            .then(response => {
              //Download executing tasks count for each queue
              const executing = _.get(response, 'data.count', null);
              if (executing)
                dispatch({
                  type: LOAD_EXECUTING_TASKS_COUNT,
                  payload: {
                    name: queue_name,
                    count: executing
                  }
                });
            });
        });
      }
    });

  // Download total tasks count
  sendRequest("get", "jobs/search/?page_size=1")
    .then(response => {
      dispatch({
        type: LOAD_TOTAL_TASKS_COUNT,
        payload: response.data.count
      });
    });
};
