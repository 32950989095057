import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { Spinner } from 'reactstrap';

import axios from '../../../main/utils/axios/axiosInstance';
import {
  Container,
  NormalCol,
  CustomButton,
  Arrow
} from '../../styles/styled-components/jobs/Results';
import { Alert, Text, SpaceSpinner, IframeButton, IframeContainer } from './../../styles/styled-components/jobs/Monitoring';
import { FullScreen, MinScreen } from '../../../assets/img/task/icons';

@withTranslation()
export default class Results extends Component {
  state = {
    isOutputFilesActive: this.props.url ? false : true,
    isShowResultsActive: true,
    loadingSpinner: true,
    isFullScreen: false,
    isFullScreen2: false
  };

  toggleOutputFilesActive = () => {
    this.setState({ isOutputFilesActive: !this.state.isOutputFilesActive });
  };

  toggleShowResultsActive = () => {
    this.setState({ isShowResultsActive: !this.state.isShowResultsActive });
  };

  hideSpinner = () => {
    this.setState({ loadingSpinner: false });
  };

  toggleIframeSize = () => {
    this.setState(prevState => ({
      isFullScreen: !prevState.isFullScreen
    }));
  };

  toggleIframeSize2 = () => {
    this.setState(prevState => ({
      isFullScreen2: !prevState.isFullScreen2
    }));
  };

  componentDidMount() {
    const outputFile = this.props.files?.find(file => file.type === 'OUTPUT' && file.ui_url);
    const storageUrl = outputFile ? outputFile.ui_url : '';
    const url = this.props.url;

    window.onmessage = (e) => {
      if (url && e.origin === new URL(url).origin && e.data.id === "getFile") {
        axios
          .get(`${storageUrl}${e.data?.data}?format=raw`)
          .then((res) => {
            e.ports[0].postMessage({
              data: JSON.parse(JSON.stringify(res)),
              error: null,
              id: "getFile",
            });
          })
          .catch((err) => {
            e.ports[0].postMessage({
              data: null,
              error: err,
              id: "getFile",
            });
          });
      }
    };
  }
  
  componentWillUnmount() {
    window.onmessage = null;
  }

  renderSpinner = () => {
    return (
      <SpaceSpinner>
        <Spinner color="primary"/>
      </SpaceSpinner>
    );
  };

  render() {
    const { t } = this.props;
    const { url, storages, files } = this.props;
    const { isFullScreen, isFullScreen2, isOutputFilesActive, isShowResultsActive, loadingSpinner } = this.state;

    const outputFile = files?.find(file => file.type === 'OUTPUT' && file.ui_url);
    const storageUrl = outputFile ? outputFile.ui_url : '';
    const isResults = url || outputFile;

    const [ urlWithoutQuery, query ] = outputFile?.ui_url.includes('?') ? 
      storageUrl.split('?') :
      [ storageUrl, '' ];

    const newQuery = new URLSearchParams(query);
    newQuery.set('lng', i18n.language);
    const finalUrl = `${urlWithoutQuery}?${newQuery.toString()}`;
    
    return (
      <Container>
        { isResults ?
          <NormalCol>
            { loadingSpinner && this.renderSpinner() }
            { url && storages && 
              <>
                { !loadingSpinner && 
                  <CustomButton onClick={this.toggleShowResultsActive}>
                    <div>{this.props.t(`task.task_results`)}</div> 
                    <Arrow active={!isShowResultsActive}/>
                  </CustomButton>
                }
                { isShowResultsActive &&
                  <IframeContainer isFullScreen={isFullScreen}>
                    <iframe title={t("show_results")} src={url} onLoad={this.hideSpinner} sandbox="allow-scripts allow-same-origin allow-downloads"/>
                    <IframeButton onClick={this.toggleIframeSize} id="toggleButton">
                      {isFullScreen ? <img src={MinScreen} alt="MinScreen"/> : <img src={FullScreen} alt="FullScreen"/>}
                    </IframeButton>
                    { loadingSpinner && this.renderSpinner() }
                  </IframeContainer>
                }
              </>
            } 
            <>
              { finalUrl && !loadingSpinner &&
                <CustomButton onClick={this.toggleOutputFilesActive}>
                  {this.props.t(`task.result_directory`)}
                  <Arrow active={!isOutputFilesActive}/>
                </CustomButton>
              }
              { isOutputFilesActive &&
                <IframeContainer isFullScreen={isFullScreen2}>
                  <iframe title={t('output_files')} src={finalUrl} onLoad={this.hideSpinner}/>
                  { !loadingSpinner &&
                    <IframeButton onClick={this.toggleIframeSize2} id="toggleButton">
                      {isFullScreen ? <img src={MinScreen} alt="MinScreen"/> : <img src={FullScreen} alt="FullScreen"/>}
                    </IframeButton>
                  }
                </IframeContainer>
              }
            </>  
          </NormalCol>
          : 
          <Alert>
            <Text>{this.props.t(`task.alert`)}</Text>
          </Alert>
        }
      </Container>
    );
  }
};

Results.propTypes = {
  t: PropTypes.func, //HOC
  i18n: PropTypes.object, //HOC
  url: PropTypes.string,
  files: PropTypes.array,
  storages: PropTypes.object
};