import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Pagination = (props) => {
  const [ visiblePages, setVisiblePages ] = useState([]);

  const filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages);
  };

  useEffect(() =>{
    const getVisiblePages = (page, total) => {
      const size = 5;
      if (total <= size) {
        return filterPages([ 1, 2, 3, 4, 5 ], total);
      }
      else {
        if (page > 2 && page < total-1) {
          return [ 1, page-1, page, page+1, total ];
        }
        else if (page === 2){
          return [ 1, 2, 3, '...', total ];
        }
        else if (page === total-1){
          return [ 1, '...', total-2, total-1, total ];
        }
        else {
          return [ 1, 2, '...', total-1, total ];
        }
      }
    };
      
    setVisiblePages(getVisiblePages(props.page+1, props.pages));
  }, [ props.pages, props.page ]);

  const changePage = (page) => {
    const activePage = props.page + 1;
    if (page === activePage) {
      return;
    }

    props.onPageChange(page - 1);
  };

  const activePage = props.page + 1;
  return (
    <div className='center pagination p-3'>
      <div>
        <button
          className='page-button page-button-arrow'
          onClick={() => {
            if (activePage === 1) return;
            changePage(activePage - 1);
          }}
          disabled={activePage === 1}
        >
          {'<'}
        </button>
      </div>
      <div className='d-flex'>
        {visiblePages.map((page, index) => {
          return (
            <div key={index} className='p-2'>
              {page !== '...' ?
                <button
                  className={
                    activePage === page
                      ? 'page-button page-button--active'
                      : 'page-button'
                  }
                  onClick={changePage.bind(null, page)}
                >
                  {page}
                </button>
                : <button
                  className={'page-button'}
                >{page}</button>}
            </div>
          );
        })}
      </div>
      <div>
        <button
          className='page-button page-button-arrow'
          onClick={() => {
            if (activePage === props.pages) return;
            changePage(activePage + 1);
          }}
          disabled={activePage === props.pages}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
};

export default Pagination;

Pagination.propTypes = {
  pages: PropTypes.number,
  page: PropTypes.number,
  onPageChange: PropTypes.func,
};