/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { ExternalFormIframe, IframeButton, IframeContainer } from '../../styles/styled-components/externalForm';
import { FullScreen, MinScreen } from '../../../assets/img/task/icons';

const ExternalForm = ({ GUIUrl, onSubmit }) => {
  const [ isFullScreen, setIsFullScreen ] = useState(false);

  window.onmessage = function (e) {
    if (e.origin === new URL(GUIUrl).origin){
      onSubmit({ formData: e.data });
    }
  };

  return (
    <IframeContainer isFullScreen={isFullScreen}>
      <IframeButton onClick={() => setIsFullScreen(!isFullScreen)} id="toggleButton">
        {isFullScreen ? <img src={MinScreen} alt="MinScreen"/> : <img src={FullScreen} alt="FullScreen"/>}
      </IframeButton>
      <ExternalFormIframe
        src={GUIUrl}
        title="External GUI"
        id="external_gui"
      />
    </IframeContainer>
  );
};
 
ExternalForm.propTypes = {
  GUIUrl: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ExternalForm;
 