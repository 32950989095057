/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { UncontrolledTooltip } from 'reactstrap';

@withTranslation()
export default class LocalTimeComponent extends Component {
  renderTooltip(id) {
    return (
      <UncontrolledTooltip placement="auto" target={id}>
        {this.props.t('local_time')}
      </UncontrolledTooltip>
    );
  }

  render() {
    return (
      <span>
        <span id={this.props.id} className={this.props.className}>
          {this.props.children}
        </span>
        {this.renderTooltip(this.props.id)}
      </span>
    );
  }
}

LocalTimeComponent.propTypes = {
  t: PropTypes.func, //HOC
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};
