/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';

import MainApp from './main/index';
import i18n from './main/utils/i18nConfigProvider';
import { initializeSentry } from './main/utils/logger';
import { __env } from './envloader';
import { getSS, removeSS } from './main/utils/sessionStorageProvider';
import { logInRedirect } from './oidc-middleware/utils/loginUtils';
import { checkIfLoginIsPossible, loginCallbackPath } from './main/utils/authUtils';

initializeSentry();

// If url in not login callback, then try to make new silent login
if (!window.location.href.includes(loginCallbackPath))
  removeSS('login_in_progress');

if (
  checkIfLoginIsPossible()
  && __env.AUTH_AUTO_ON_APP_OPEN
  && !getSS('login_in_progress', true)
  && !getSS('logout_in_progress')
  && !getSS('sso_down')
)
  logInRedirect('none');
else {
  removeSS('sso_down');
  ReactDOM.render(
    <I18nextProvider i18n={i18n}><MainApp /></I18nextProvider>,
    document.getElementById('root')
  );
}

