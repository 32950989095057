/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import { Map, fromJS } from 'immutable';

import { setGV, clearGV } from '../../main/utils/globalVariablesProvider';
import { LOG_IN_SUCCESS, FORCED_LOG_OUT, LOG_IN_FINISH, LOG_IN_FAIL } from '../actions/login';

export const loginReducer = (state = Map(), action) => {
  switch (action.type) {

  case LOG_IN_FINISH:
    return state.set("login_finishing_in_progress", true);

  case LOG_IN_SUCCESS:
    setGV('accessToken', action.payload.data.accessToken);
    setGV('refreshToken', action.payload.data.refreshToken);
    return state
      .set("loginData", fromJS(action.payload.data))
      .set("login_finishing_in_progress", false);

  case LOG_IN_FAIL:
    return state.set("login_finishing_in_progress", false);

  case FORCED_LOG_OUT:
    clearGV();
    return state.set("loginData", null);

  default:
    return state;
  }
};
