import styled, { css, keyframes } from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

import { theme } from '../theme';
import { NormalColumn, NormalRow } from './GlobalStyles';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const Breadcrumbs = styled.div`
    height: 48px;
    margin-bottom: 6px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: ${theme.colors.primary[500]};

    & > :nth-child(3) {
      padding-left: 4px;
      line-height: 20px;
      letter-spacing: 0.28px;
      color: ${theme.colors.primary["900"]};
      font-size: ${theme.fontSize.medium};
      font-weight: ${theme.fontWeight.medium};
    }

    a:hover {
        text-decoration: none;
        color: ${theme.colors.primary[500]};
    }
`;

export const Link = styled(RouterLink)`
  &:hover {
    text-decoration: none;
  }
`;

export const Container = styled.div`
  width: 100%;
  height: 100%;
`;

export const CustomDropdown = styled(Dropdown)`
  height: 32px;
`;

export const CustomDropdownToggle = styled(DropdownToggle)`
  height: 32px;
  border: none;
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  color: ${theme.colors.grey["700"]} !important;
  background-color: white !important;
  font-weight: ${theme.fontWeight.medium};
  width: 230px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: white !important;
    color: ${theme.colors.grey["700"]} !important;
  }

  &:active, &:focus {
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 1px 2px 0px rgba(0, 0, 0, 0.06) !important;
  }
`;

export const DropdownText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
`;

export const CustomDropdownMenu = styled(DropdownMenu)`
  box-shadow: 0px 5px 10px -2px rgba(0, 0, 0, 0.06), 0px 10px 15px -3px rgba(17, 24, 38, 0.10), 0px 0px 0px 1px rgba(152, 161, 178, 0.10);
  border-radius: 6px;
  width: 230px;
`;

export const CustomDropdownItem = styled(DropdownItem)`
  ${({ isChoosen }) => isChoosen && css`
    background: ${theme.colors.primary["500"]} !important;
    color: ${theme.colors.white} !important;
  `}

  max-width: 210px !important;
  margin: 10px;
  color: ${theme.colors.grey["700"]};
  border-radius: 4px !important;
  height: 28px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 10px;

  &:hover {
    background: ${theme.colors.primary["0"]};
  }

  &:active, :focus {
    background: ${theme.colors.primary["50"]};
    color: ${theme.colors.grey["700"]};
  }
`;

export const Main = styled(NormalColumn)`
  max-height: calc(100vh - 56px); 
  overflow-y: auto;
  scrollbar-width: 3px;
  padding-right: 15px;
`;
export const MainContent = styled.div`
  max-height: calc(100vh - 56px); 
  overflow-y: auto;
  scrollbar-width: 3px;
  padding-right: 15px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.span`
  color: ${theme.colors.grey["850"]};
  font-size: ${theme.fontSize.xxLarge};
  font-weight: ${theme.fontWeight.bold};
  line-height: 32px;
`;

export const SearchContainer = styled.div`
  position: relative;
  width: 320px;
`;

export const SearchInput = styled.input`
  width: 100%;
  padding: 6px 40px 6px 10px;
  border: none;
  border-radius: 6px;
  box-shadow: 0px 0px 0px 1px rgba(134, 143, 160, 0.40), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  height: 32px;

  &::placeholder {
    color: ${theme.colors.grey["300"]};
    font-size: ${theme.fontSize.medium};
    line-height: 20px;
  }

  &:focus {
    outline: none;
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(134, 143, 160, 0.32), 0px 1px 2px 0px rgba(0, 0, 0, 0.06);
  }
`;

export const Icon = styled.img`
  position: absolute;
  right: 12px;
  top: 50%;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const CountOfTemplates = styled.div`
  color: ${theme.colors.grey["700"]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  padding: 7px 0 16px 0;
  
  span {
    padding-left: 4px;
    font-weight: ${theme.fontWeight.bold};
  }
`;

const slideInFromRight = keyframes`
    0% {
        opacity: 0;
        transform: translateX(100%);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
`;

export const TemplatesContainer = styled.div`
  display: flex;
  gap: 20px;
  padding-top: 12px;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: 3px;
  max-height: ${({ scroll }) => scroll ? "100%": "auto" };
`;

export const TemplateContainer = styled.div`
  border-radius: 6px;
  background-color: ${({ recently }) => recently ? "#EDEDFC" : "trnsparent"};
  position: relative;
  animation: ${slideInFromRight} 500ms;
  width: ${({ isFilter }) => isFilter ? "250px" : "320px" };
  flex-basis: calc(24% - 10px);
  flex-grow: 0;
  flex-shrink: 0;
  height: 300px;
  box-shadow: 0px 4px 14px 0px rgba(5, 36, 80, 0.10);
  transition: all 500ms;
`;

export const IconContainer = styled.div`
  cursor: pointer;
  background: ${theme.colors.grey["0"]};
  height: 144px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TemplateMain = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 16px 16px 16px;
`;

export const TemplateTitle = styled.div`
  cursor: pointer;
  color: ${({ recently }) => recently ? "#3734A3" : theme.colors.grey["700"]};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.bold};;
  letter-spacing: 0.08px;

  display: -webkit-box;
  max-height: 48px;  
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const TemplateDescription = styled.div`
  color: ${theme.colors.grey["600"]};
  font-size: ${theme.fontSize.medium};
  line-height: 20px; 
  padding-top: 6px;

  display: -webkit-box;
  max-height: 48px;  
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
`;

export const ButtonsContainer = styled(NormalRow)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  gap: 1rem;
  width: auto;
`;

export const NewJob = styled(Link)`
  ${({ disabled }) => disabled ?`
      box-shadow: rgba(134, 143, 160, 0.4) 0px 0px 0px 1px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px !important;
      color: rgb(70, 79, 96) !important;
      cursor: not-allowed;
    `
    :
    "box-shadow: 0px 0px 0px 1px rgba(70, 79, 96, 0.16), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);"};

  text-decoration: none;
  width: 120px;
  height: 22px;
  position: absolute; 
  bottom: 16px;
  right: 16px; 
  border-radius: 4px;
  background-color: white;
  color: ${theme.colors.grey["700"]};
  
  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;

  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};

  &:hover {
    color: ${theme.colors.grey["900"]};
    text-decoration: none;
  }

  &:active {
    color: ${theme.colors.grey["700"]};
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px rgba(70, 79, 96, 0.32), 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
`;

export const OrderAgain = styled(Link)`
  ${({ disabled }) => disabled && css`
      cursor: not-allowed;
      background: ${theme.colors.primary["500"]} !important;
      box-shadow: none !important;
  `};

  text-decoration: none;
  width: 120px;
  height: 22px;
  border: none;
  border-radius: 4px;
  background: ${theme.colors.primary["500"]};
  color: white;

  display: flex;
  padding: 2px 8px;
  justify-content: center;
  align-items: center;

  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  box-shadow: 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);

  &:hover {
    text-decoration: none;
    color: white;
    background: ${theme.colors.primary["600"]};
    box-shadow: 0px 0px 0px 1px ${theme.colors.primary["600"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:active {
    background: ${theme.colors.primary["500"]};
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
`;

export const FilterButton = styled.button`
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 6px;
  background: ${theme.colors.primary["500"]};

  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);

  &:hover {
    background: ${theme.colors.primary["600"]};
    box-shadow: 0px 0px 0px 1px ${theme.colors.primary["600"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:active {
    background: ${theme.colors.primary["500"]};
    box-shadow: 0px 0px 0px 4px rgba(94, 90, 219, 0.40), 0px 0px 0px 1px ${theme.colors.primary["500"]}, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }

  &:disabled {
    color: var(--indigo-0, #EDEDFC);
    background: var(--indigo-300, #9E9BF5);
    box-shadow: 0px 0px 0px 1px #9E9BF5, 0px 1px 1px 0px rgba(0, 0, 0, 0.10);
  }
`;

export const CollapseText = styled.div`
  color: ${theme.colors.grey['900']};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  white-space: nowrap;
`;

export const RecentlyTemplate = styled.div`
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
`;

export const FilterContainer = styled(NormalColumn)`
  max-height: 100%;
  max-width: 250px;
  border-right: 1px solid ${theme.colors.grey['100']};
  overflow: hidden;
  transition: all 0.3s ease-in-out;

  ${({ isOpen }) => isOpen 
    ? `
      width: 300px;
      padding: 30px 14px 20px 14px;
    `
    : `
      white-space: nowrap;
      padding-top: 30px;
      width: 0;
    `}
`;

export const FilterTitle = styled.div`
  color: ${theme.colors.grey['900']};
  font-size: ${theme.fontSize.large};
  font-weight: ${theme.fontWeight.bold};
  letter-spacing: 0.08px;
  padding-bottom: 6px;
  white-space: nowrap;
`;

export const Tags = styled.div`
  color: var(--gray-400, #868FA0);
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.36px;
  white-space: nowrap;
`;

export const SortBy = styled(NormalRow)`
  padding: 0 10px;
  justify-content: space-between;
  color: ${theme.colors.grey['400']};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.medium};
  line-height: 20px;
  letter-spacing: 0.28px;
  text-transform: capitalize;
  cursor: pointer;
  padding: 6px 0;
  white-space: nowrap;
  padding-right: 7px;

  &:hover {
    border-radius: 4px;
    background: ${theme.colors.primary['0']};
  }
`;

export const AmountofTags = styled.div`
  display: flex;
  padding: 0px 6px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${theme.colors.primary['0']};

  color:  ${theme.colors.primary['500']};
  font-size: ${theme.fontSize.extraSmall};
  font-weight: ${theme.fontWeight.medium};
  line-height: 16px;
  letter-spacing: 0.3px;
  white-space: nowrap;
`;

export const SortByContainer = styled(NormalColumn)`
  gap: 9px;
  margin-top: 12px;
  height: calc(100vh - 270px);
  overflow: hidden;
  overflow-y: auto;
  scrollbar-width: 3px;
  white-space: nowrap;
`;

export const AlertContainer = styled.div`
  width: 100%;
  background: ${theme.colors.lightGrey};
  margin-top: 12px;
  padding: 16px 0 16px 70px;
  display: flex;
  gap: 115px;
`;

export const CommunicatContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const TitleComunicat = styled.div`
  color: ${theme.colors.grey["900"]};
  font-size: ${theme.fontSize.mediumLarge};
  font-weight: ${theme.fontWeight.medium};
  line-height: 28px;
`;

export const BodyComunicat = styled.div`
  color: ${theme.colors.grey["600"]};
  font-size: ${theme.fontSize.medium};
  font-weight: ${theme.fontWeight.normal};
  line-height: 20px;
`;