import shajs from 'sha.js';
import URLSafeBase64 from 'urlsafe-base64';
import randomstring from 'randomstring';
import qs from 'qs';
import IdTokenVerifier from 'idtoken-verifier';
import i18next from 'i18next';

import { __env } from '../../envloader';
import { getAuthConfig, loginCallbackPath } from '../../main/utils/authUtils';
import { clearOwnSS, setSS } from '../../main/utils/sessionStorageProvider';
import { getLogger } from '../../main/utils/logger';

export function getRedirectUri() {
  const urlArray = window.location.href.split("/");
  return urlArray[0] + "//" + urlArray[2] + __env.PUBLIC_URL + loginCallbackPath;
}

export function logInRedirect(prompt, mode) {
  clearOwnSS();
  if (mode === 'after-token-refresh-error')
    setSS('after-token-refresh-error', 'true');
  // preserve url before login callback
  setSS('redirect_url_pathname', window.location.pathname.replace(__env.PUBLIC_URL,''));
  setSS('redirect_url_search', window.location.search);

  const redirect_uri = getRedirectUri();
  const client_id = __env.AUTH_CLIENT_ID;

  setSS('login_in_progress', true);
  const state = randomstring.generate();
  setSS('state', state);
  const code_verifier = randomstring.generate(64);
  setSS('code_verifier', code_verifier);
  const code_challenge = URLSafeBase64.encode(shajs('sha256').update(code_verifier).digest());

  const params = {
    prompt: prompt,
    response_type: 'code',
    scope: 'openid profile email',
    client_id: client_id,
    redirect_uri: redirect_uri,
    state: state,
    code_challenge: code_challenge,
    code_challenge_method: 'S256'
  };

  getAuthConfig().then((config) => {
    window.location.assign(config.AUTH_AUTHORIZATION_ENDPOINT + "?" + qs.stringify(params));
  }).catch(error => {
    getLogger({ loggerName: 'sso_login' }).error(error);
    if (prompt === 'none') {
      window.location.reload();
    }
  });
}

export function verifyAccessToken(accessToken) {
  if (__env.AUTH_VERIFY_ACCESS_TOKEN === true) {
    const verifier = new IdTokenVerifier();
    const aud = verifier.decode(accessToken).payload.aud;
    if ((Array.isArray(aud) && !aud.includes(__env.AUTH_CLIENT_ID)) || (typeof aud === 'string' && __env.AUTH_CLIENT_ID !== aud)) {
      const error = Error(i18next.t('common:access_token_audience_error'));
      throw error;
    }
  }
}