/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Button, InputGroupAddon } from 'reactstrap';
import { IoIosRefresh } from 'react-icons/io';
import PropTypes from 'prop-types';

import { ErrorBoundary } from '../../main/hoc/errorboundary';
import '../styles/refresh.scss';
import AutoRefresh from './autoRefresh';

@ErrorBoundary((props) => {
  return `${props.t('refresh_feature_component')}`;
})
@withTranslation()
export default class RefreshFeature extends Component {
  state = {
    time: 30,
  };

  setTime = (newTime) => {
    this.setState({ time: newTime });
  };

  render() {
    const { t } = this.props;
    const { time } = this.state;

    return (
      <div className='refresh-feature'>
        <span style={{ paddingRight: 0 }}>
          {t('automatic_refresh')}
        </span>
        <AutoRefresh refresh={this.props.fetchData} time={time} setTime={this.setTime} />
        <InputGroupAddon addonType="append">
          <Button
            data-testid='refresh-feature-button'
            id='refreshButton'
            color="primary"
            outline
            className='refresh-button mb-1'
            size='sm'
            onClick={() => { this.setTime(30); this.props.fetchData(); }}
          >
            <IoIosRefresh size={18} color='#777676'/>
          </Button>
        </InputGroupAddon>
      </div>
    );
  }
}

RefreshFeature.propTypes = {
  minValue: PropTypes.number.isRequired,
  maxValue: PropTypes.number.isRequired,
  default: PropTypes.number.isRequired,
  fetchData: PropTypes.func.isRequired,
  t: PropTypes.func, //HOC
};
