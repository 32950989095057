/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import CleanButton from '../../components/cleanButton';

const DefaultFilter = ({ column, filter, onChange, t }) => (
  <div className='pl-4 pr-4'>
    <p className="filter-header">
      {typeof column.Header === 'function' ?  column.Header() : column.Header}
      {filter && (<CleanButton callback={event => onChange("")}/>)}
    </p>
    <input
      className='w-100'
      value={filter ? filter.value : ""}
      onChange={event => onChange(event.target.value)}
      placeholder={t("type_value")} />
  </div>
);


export default withTranslation()(DefaultFilter);

DefaultFilter.propTypes = {
  column: PropTypes.object,
  filter: PropTypes.object,
  onChange: PropTypes.func,
  t: PropTypes.func, //HOC
};
