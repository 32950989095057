import React, { PureComponent } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

@withTranslation()
export default class XAxisTick extends PureComponent {
  render() {
    const { payload, x, y } = this.props;
    return [
      <text id={'chart-x-axis-tick-' + payload.index} key={payload.index} x={x} y={y} dy={16} textAnchor="middle">
        {payload.value}
      </text>,
      <UncontrolledTooltip placement="auto" target={'chart-x-axis-tick-' + payload.index} key={payload.index + '_tooltip'}>
        {this.props.t('local_time')}
      </UncontrolledTooltip>
    ];
  }
}

XAxisTick.propTypes = {
  payload: PropTypes.object,
  x: PropTypes.number,
  y: PropTypes.number,
  t: PropTypes.func, //HOC
};