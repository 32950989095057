/*******************************************************************************
 * Niniejszy plik jest częścią pakietu programistycznego QCG.
 * Wszelkie prawa do tego oprogramowania przysługują
 * Instytutowi Chemii Bioorganicznej -
 * Poznańskie Centrum Superkomputerowo-Sieciowe z siedzibą w Poznaniu.
 ******************************************************************************/

import React, { useState, useCallback } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Progress,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import axiosInstance from '@axios';
import { Dropzone } from '@ibis-ui/dropzone/dropzone';

const guid = () => {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return (
    s4() +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    "-" +
    s4() +
    s4() +
    s4()
  );
};

const DirectUpload = ({ onClose, submitValue, url }) => {
  const { t } = useTranslation();
  const [ value, setValue ] = useState(undefined);
  const [ cancel, setCancel ] = useState(false);
  const [ progress, setProgress ] = useState(null);
  const [ file, setFile ] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const controller = new AbortController();

      setFile(acceptedFiles[0]);
      const file = acceptedFiles[0];

      setProgress(0);
      let formData = new FormData();
      formData.append("content", file);

      const IBISUrl = `${url}${guid()}/`;
      axiosInstance({
        data: formData,
        url: IBISUrl + file.name,
        method: "post",
        signal: controller.signal,
        contentType: "multipart/form-data",
        onUploadProgress: (progressEvent) => {
          setProgress(
            Math.round((progressEvent.loaded * 100) / progressEvent.total)
          );
        },
      })
        .then(() => {
          submitValue(IBISUrl + file.name);
        })
        .catch(() => {
          setValue(null);
        })
        .finally(() => {
          setFile(null);
          setProgress(null);
        });

      setCancel(controller);
    },
    [ submitValue, url ]
  );

  return (
    <>
      <Dropzone onDrop={onDrop} />

      <Modal isOpen={file ? true : false}>
        <ModalHeader toggle={onClose}>
          {t("directUploadWidget.title")}
        </ModalHeader>
        <ModalBody>
          <span>{value}</span>
          <div className="pt-3 d-flex">
            <div className="align-self-center w-100 px-3">
              {!value && <span>{t("directUploadWidget.attach_file")}</span>}
              {progress !== null ? (
                <Progress className="w-100" value={progress} />
              ) : (
                <span>{file?.name}</span>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => cancel.abort()}>
            {t("directUploadWidget.cancel")}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

DirectUpload.propTypes = {
  t: PropTypes.func, //HOC
  onClose: PropTypes.func,
  submitValue: PropTypes.func,
  url: PropTypes.string,
  file: PropTypes.object,
};

export default DirectUpload;
