import styled from 'styled-components';

import { theme } from '../../styles';

export const StyledDropzone = styled.div`
  border: 1.6px dashed ${theme.colors.grey["100"]};
  border-radius: 8px;
  cursor: pointer;
  background-color: white;
  ${({ disabled }) => disabled ? ` background-color: ${theme.colors.grey[50]}` : ""};
`;

export const StyledNoFilesText = styled.span`
  font-weight: 500;
  color: ${theme.colors.grey[700]};
`;

export const StyledAddFiles = styled.pattern`
  color: ${({ disabled }) => disabled ? theme.colors.grey[200] : theme.colors.primary[600]};
`;

export const StyledLightText = styled.span`
  padding-left: 0.25rem;
  color: ${theme.colors.grey[200]};
`;